/**
 * @generated SignedSource<<0575157802836d85c1379be1dab6d351>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_employee_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$variables = {
  where: project_subcontractor_employee_bool_exp;
};
export type ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$data = {
  readonly delete_project_subcontractor_employee: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation = {
  response: ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$data;
  variables: ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "delete_project_subcontractor_employee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "delete_project_subcontractor_employee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "441aa3dbeefce1b054d00e5539cf3d4a",
    "id": null,
    "metadata": {},
    "name": "ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation(\n  $where: project_subcontractor_employee_bool_exp!\n) {\n  delete_project_subcontractor_employee(where: $where) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5dd6e4da8707f61f9234777b8011be05";

export default node;
