/**
 * @generated SignedSource<<27ee076737948cbf98a68dd22e436218>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_orientation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationBackgroundCheckMutation$variables = {
  objects: ReadonlyArray<user_orientation_insert_input>;
};
export type GCProjectOrientationBackgroundCheckMutation$data = {
  readonly insert_user_orientation: {
    readonly returning: ReadonlyArray<{
      readonly background_check_done_by_uid: string | null | undefined;
    }>;
  } | null | undefined;
};
export type GCProjectOrientationBackgroundCheckMutation = {
  response: GCProjectOrientationBackgroundCheckMutation$data;
  variables: GCProjectOrientationBackgroundCheckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "user_orientation_user_id_project_id_key",
      "update_columns": [
        "background_check_done",
        "background_check_updated_at",
        "background_check_done_by_uid"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "background_check_done_by_uid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationBackgroundCheckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectOrientationBackgroundCheckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a2d44d58b005df946670a6132cd1759",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationBackgroundCheckMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationBackgroundCheckMutation(\n  $objects: [user_orientation_insert_input!]!\n) {\n  insert_user_orientation(objects: $objects, on_conflict: {constraint: user_orientation_user_id_project_id_key, update_columns: [background_check_done, background_check_updated_at, background_check_done_by_uid]}) {\n    returning {\n      background_check_done_by_uid\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12cda5d41afa58cdf3b0c20f03448499";

export default node;
