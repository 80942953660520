import { Popover, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  useGetIncidentByPkQuery,
  useUpdateIncidentMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const IncidentStatus: React.FC = () => {
  const { incidentId } = useParams();
  const authUser = useAuthUser();
  if (!incidentId) throw new Error("");

  const { data: incident } = useGetIncidentByPkQuery({
    variables: { incidentId: incidentId },
    fetchPolicy: "cache-only",
  });

  const [updateIncident] = useUpdateIncidentMutation();

  const updateIncidentStatus = async (status: string) => {
    if (!incident?.incident_by_pk) return;
    const updatedIncident = { ...incident.incident_by_pk, status: status };
    const patch = createIncidentPatch(updatedIncident, incident.incident_by_pk);
    const comment = `Updated "Incident status" to ${status}`;
    await updateIncident({
      variables: {
        incidentId: incidentId,
        _set: { status: status },
        objects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "updated-incident-status",
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident.incident_by_pk,
          status: status,
          id: incidentId,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <div className="flex flex-row items-center gap-1">
      <Select
        className={`w-8`}
        value={incident?.incident_by_pk?.status ?? undefined}
        onChange={async (option) => {
          await updateIncidentStatus(option);
        }}
        options={[
          {
            label: "Incident Status",
            options: [
              { value: "open", label: "Open" },
              { value: "closed", label: "Closed" },
              { value: "pending", label: "In-Progress" },
            ],
          },
        ]}
      />
      <Popover
        trigger={"hover"}
        placement="bottomLeft"
        title={"Incident Status Definitions"}
        content={
          <div className={"flex flex-col w-24 space-y-1"}>
            <div>
              <strong>Open:</strong> Additional details or information required
            </div>
            <div>
              <strong>In-progress:</strong> All general details or information
              have been provided. In-Progress incidents are following cases that
              have not been closed, like a worker that has not returned to work
            </div>
            <div>
              <strong>Closed:</strong> All information is collected and the
              incident is considered 100% complete
            </div>
          </div>
        }
      >
        <InfoCircleOutlined />
      </Popover>
    </div>
  );
};

export default IncidentStatus;
