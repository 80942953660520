import {
  useUpdateInjuryDetailInjuryTypesMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateInjuryDetailInjuryTypes = () => {
  const [updateInjuryDetailInjuryTypes] =
    useUpdateInjuryDetailInjuryTypesMutation();
  const authUser = useAuthUser();

  const updateInjuryInjuryTypes = async (
    toBeInsertedInjuryTypeIds: Array<string>,
    toBeDeletedInjuryTypeIds: Array<string>,
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    injuredUserId: string,
    comment: string,
  ) => {
    const injuredUser = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    );
    const injuryDetail = injuredUser?.injury_detail;

    if (!injuryDetail) return;

    const filteredInjuryTypes =
      injuryDetail?.injury_detail_injury_types.filter(
        (injuryType) => !toBeDeletedInjuryTypeIds.includes(injuryType.id),
      ) ?? [];

    const updatedIncident = {
      ...incident,
      injured_users: incident.injured_users.map((injuredUser) => {
        if (injuredUser.id === injuredUserId) {
          return {
            ...injuredUser,
            injury_detail: {
              ...injuryDetail,
              injury_detail_injury_types: [
                ...filteredInjuryTypes,
                ...toBeInsertedInjuryTypeIds.map((id) => ({
                  id: id,
                  injury_detail_id: injuryDetail.id,
                })),
              ],
            },
          };
        }
        return injuredUser;
      }),
    } as typeof incident;

    const patch = createIncidentPatch(updatedIncident, incident);

    await updateInjuryDetailInjuryTypes({
      variables: {
        objects: toBeInsertedInjuryTypeIds.map((injuryType) => ({
          injury_type: injuryType,
          injury_detail_id: injuryDetail.id,
        })),
        deleteWhere: { id: { _in: toBeDeletedInjuryTypeIds } },
        editObjects: {
          comment: comment,
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          patch: patch,
          edit_type: "injury-detail-injury-type-update",
        },
      },
      update(cache, result) {
        const insertedInjuryTypes =
          result.data?.insert_injury_detail_injury_type?.returning;
        const deletedInjuryTypes =
          result.data?.delete_injury_detail_injury_type?.returning;
        const filteredInjuryTypes = incident.injured_users
          .find((user) => user.id === injuredUserId)
          ?.injury_detail?.injury_detail_injury_types.filter(
            (injuryType) =>
              deletedInjuryTypes?.findIndex(
                (injury) => injury.injury_type === injuryType.injury_type,
              ) === -1,
          );

        const injuredUserIndex = incident.injured_users.findIndex(
          (injuredUser) => injuredUser.id === injuredUserId,
        );
        if (injuredUserIndex === -1) return;

        const injuredUsers = incident.injured_users.map((user, index) => {
          if (index !== injuredUserIndex) {
            return user;
          }
          const injuryDetail = user.injury_detail;
          if (injuryDetail && insertedInjuryTypes && filteredInjuryTypes) {
            const newInjuryDetail = {
              ...injuryDetail,
              injury_detail_injury_types: [
                ...filteredInjuryTypes,
                ...insertedInjuryTypes,
              ],
            };
            return {
              ...user,
              injury_detail: newInjuryDetail,
            };
          }
          return user;
        });

        const updatedIncident = {
          ...incident,
          injured_users: injuredUsers,
        };

        cache.writeQuery<GetIncidentByPkQuery>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });
  };

  return [updateInjuryInjuryTypes];
};

export default useUpdateInjuryDetailInjuryTypes;
