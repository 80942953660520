/**
 * @generated SignedSource<<11206865150a039706f7f56627b42d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_safety_report_not_require_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation$variables = {
  object: project_subcontractor_safety_report_not_require_period_insert_input;
};
export type usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation$data = {
  readonly insert_project_subcontractor_safety_report_not_require_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation = {
  response: usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation$data;
  variables: usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_safety_report_not_require_period_project_",
          "update_columns": [
            "close_date"
          ]
        }
      }
    ],
    "concreteType": "project_subcontractor_safety_report_not_require_period",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_safety_report_not_require_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "420d1bfcc2e6ad1267e6f04ba67d96be",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsSub_PTPNotRequire_UpsertMutation(\n  $object: project_subcontractor_safety_report_not_require_period_insert_input!\n) {\n  insert_project_subcontractor_safety_report_not_require_period_one(object: $object, on_conflict: {constraint: project_subcontractor_safety_report_not_require_period_project_, update_columns: [close_date]}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9816f39503034144c56e644e1b1e30e";

export default node;
