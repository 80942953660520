/**
 * @generated SignedSource<<fb603fb4388c8c9a470c3c54129c5864>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCProjectsInsertProjectsMutation$variables = {
  objects: ReadonlyArray<project_insert_input>;
};
export type SCProjectsInsertProjectsMutation$data = {
  readonly insert_project: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCProjectsInsertProjectsMutation = {
  response: SCProjectsInsertProjectsMutation$data;
  variables: SCProjectsInsertProjectsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectsInsertProjectsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectsInsertProjectsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47f97d5bcdeaed5296a72f03c1473652",
    "id": null,
    "metadata": {},
    "name": "SCProjectsInsertProjectsMutation",
    "operationKind": "mutation",
    "text": "mutation SCProjectsInsertProjectsMutation(\n  $objects: [project_insert_input!]!\n) {\n  insert_project(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "18583e486a94a6761514954078170b54";

export default node;
