import { useSuspenseQuery } from "@apollo/client";
import AnonProjectWorkerTable, {
  ColumnKeys,
} from "src/common/components/tables/AnonProjectWorkerTable";
import {
  GetGcAdminWorkersDocument,
  GetGcAdminWorkersQuery,
  GetGcAdminWorkersQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import useGcEmployee from "../../organization-unit/utils/useGcEmployee";
import GCDownloadCorporateWorkersModal, {
  GCDownloadCorporateWorkersModalRef,
} from "./GCDownloadCorporateWorkersModal";
import { useRef } from "react";
import Icon from "src/common/components/general/Icon";
import { MaterialSymbol } from "react-material-symbols";

const GCCorporateOfficeWorkers = () => {
  const downloadModalRef = useRef<GCDownloadCorporateWorkersModalRef>(null);
  const gc = useGcEmployee().general_contractor;
  const { data } = useSuspenseQuery<
    GetGcAdminWorkersQuery,
    GetGcAdminWorkersQueryVariables
  >(GetGcAdminWorkersDocument, {
    variables: {
      gcId: gc.id,
      projWhere: {
        general_contractors: {
          general_contractor_id: {
            _eq: gc.id,
          },
        },
      },
    },
  });
  const excludedKeys: ColumnKeys[] = ["crew"];
  if (!data.orientation.length) excludedKeys.push("corporateDate");

  return (
    <div className="bg-white h-full w-full">
      <AnonProjectWorkerTable
        excludedKeys={excludedKeys}
        gcId={gc.id}
        projectsOptions={data.project}
        where={{
          project: {
            general_contractor_id: {
              _eq: gc.id,
            },
          },
          is_last: { _eq: true },
        }}
        topBarButtons={[
          {
            icon: <MaterialSymbol icon="more_vert" />,
            secondary: true,
            onClick: () => downloadModalRef.current?.open(),
          },
        ]}
      />
      <GCDownloadCorporateWorkersModal ref={downloadModalRef} gcId={gc.id} />
    </div>
  );
};

export default GCCorporateOfficeWorkers;
