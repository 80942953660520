import { useState } from "react";
import { Card, Button } from "antd";
import {
  useGetIncidentAddressQuery,
  Address_Insert_Input,
  useUpdateAddressMutation,
  GetIncidentByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentInputField from "src/domain-features/incident-management/components/basic/InputField";
import IncidentSelectField from "./basic/SelectField";

interface IncidentAddressProps {
  incidentAddress?: NonNullable<
    GetIncidentByPkQuery["incident_by_pk"]
  >["injured_users"][number]["home_address"];
  onAddressChange: (
    object: Address_Insert_Input,
    type: "home" | "employer",
  ) => Promise<void>;
  type: "home" | "employer";
}

const IncidentAddress: React.FC<IncidentAddressProps> = ({
  incidentAddress,
  onAddressChange,
  type,
}) => {
  const [line1, setLine1] = useState<string>(incidentAddress?.line_1 ?? "");
  const [zip, setZip] = useState(incidentAddress?.zip_code ?? "");
  const [cityZipCode, setCityZipCode] = useState<string>();
  const [searchByCityOrZip, setSearchByCityOrZip] = useState(false);
  const [selectedState, setSelectedState] = useState<string>();
  const [updateAddressMutation] = useUpdateAddressMutation();
  const [selectedCityId, setSelectedCityId] = useState<string | undefined>();
  const {
    data: incidentAddressData,
    previousData,
    loading,
  } = useGetIncidentAddressQuery({
    variables: {
      cityZipCodeWhereUsingZipCode: !zip
        ? { zip_code: { _is_null: true } }
        : { zip_code: { _eq: zip } },
      cityWhere: selectedState
        ? { state_code: { _eq: selectedState } }
        : { state_code: { _is_null: true } },
      cityZipCodeWhereUsingCityId: selectedCityId
        ? { city_id: { _eq: selectedCityId } }
        : { city_id: { _is_null: true } },
    },
  });

  const incidentAddressDataResult = incidentAddressData ?? previousData;
  const cityName =
    incidentAddressDataResult?.city?.find((c) => c.id === selectedCityId)
      ?.name ?? "";

  const initialCityZipCodeId = incidentAddress?.city_zip_code_id;

  const [isUpdating, setIsUpdating] = useState(false);

  if (initialCityZipCodeId && !cityZipCode) {
    setCityZipCode(initialCityZipCodeId);
    setSelectedState(incidentAddress?.state.code ?? undefined);
  }

  const handleZipChange = async (newZip: string) => {
    setZip(newZip);
  };

  const handleAddressUpdate = async (
    addressData: Address_Insert_Input,
    type: "home" | "employer",
  ) => {
    setIsUpdating(true);
    try {
      await onAddressChange(addressData, type);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card
      title={type === "home" ? "Home Address" : "Employer Address"}
      loading={isUpdating}
    >
      <IncidentInputField
        label="Address Line 1"
        placeholder="Enter adress"
        defaultText={incidentAddress?.line_1 ?? line1}
        onSave={async (value) => {
          setLine1(value);
          if (incidentAddress) {
            await updateAddressMutation({
              variables: {
                where: { id: { _eq: incidentAddress.id } },
                _set: { line_1: value },
              },
              optimisticResponse: {
                update_address: {
                  returning: [{ id: incidentAddress.id, line_1: value }],
                },
              },
            });
          }
        }}
      />

      {!searchByCityOrZip ? (
        <>
          <IncidentInputField
            label="Zip code"
            placeholder="Enter zip code"
            defaultText={incidentAddress?.zip_code ?? zip}
            onSave={handleZipChange}
            validateAsNumber
            helperText={
              zip &&
              (zip.length !== 5
                ? "Invalid zip code"
                : incidentAddressDataResult?.city_zip_code?.length === 0
                ? "No city found"
                : undefined)
            }
          />

          {zip && (
            <IncidentSelectField
              title={"City"}
              placeholder="Select City"
              value={cityZipCode}
              loading={loading}
              options={
                incidentAddressDataResult?.city_zip_code.map((cz) => ({
                  label: cz.city.name,
                  value: cz.id,
                })) ?? []
              }
              onChange={async (option) => {
                const selectedCityZip = option?.value?.toString();
                if (!selectedCityZip) return;
                const selectedCity =
                  incidentAddressDataResult?.city_zip_code?.find(
                    (cz) => cz.id === selectedCityZip,
                  );
                if (selectedCity) {
                  setCityZipCode(selectedCityZip);
                  setSelectedState(selectedCity.city.state_code);
                  await handleAddressUpdate(
                    {
                      line_1: line1,
                      city_zip_code_id: selectedCityZip,
                      zip_code: zip,
                      state_code: selectedCity.city.state_code,
                      city: cityName,
                    },
                    type,
                  );
                }
              }}
            />
          )}

          <Button onClick={() => setSearchByCityOrZip(true)}>
            Search by City
          </Button>
        </>
      ) : (
        <>
          <IncidentSelectField
            title="State"
            value={selectedState}
            placeholder="Select State"
            options={
              incidentAddressDataResult?.state?.map((s) => ({
                label: s.name,
                value: s.code,
              })) ?? []
            }
            onChange={async (option) => {
              const stateCode = option?.value?.toString();
              setSelectedState(stateCode);
              setSelectedCityId(undefined);
              setCityZipCode(undefined);
            }}
          />

          {selectedState && (
            <IncidentSelectField
              title="City"
              value={selectedCityId}
              placeholder="Select City"
              options={
                incidentAddressDataResult?.city?.map((c) => ({
                  label: c.name,
                  value: c.id,
                })) ?? []
              }
              onChange={async (option) => {
                const cityId = option?.value?.toString();
                setSelectedCityId(cityId);
              }}
            />
          )}

          {selectedCityId && (
            <IncidentSelectField
              title="Zip Code"
              placeholder="Enter Zip Code"
              value={cityZipCode}
              options={
                incidentAddressDataResult?.cityAssociatedZipCodes?.map(
                  (cz) => ({
                    label: cz.zip_code,
                    value: cz.id,
                  }),
                ) ?? []
              }
              onChange={async (option) => {
                const selectedZipCode = option?.value?.toString();
                const zipCodeData =
                  incidentAddressDataResult?.cityAssociatedZipCodes?.find(
                    (cz) => cz.id === selectedZipCode,
                  );

                if (zipCodeData && selectedZipCode && selectedState) {
                  setZip(zipCodeData.zip_code);
                  setCityZipCode(selectedZipCode);
                  await handleAddressUpdate(
                    {
                      line_1: line1,
                      city_zip_code_id: selectedZipCode,
                      zip_code: zipCodeData.zip_code,
                      state_code: selectedState,
                      city: cityName,
                    },
                    type,
                  );
                }
              }}
            />
          )}

          <Button onClick={() => setSearchByCityOrZip(false)}>
            Search by Zip Code
          </Button>
        </>
      )}
    </Card>
  );
};

export default IncidentAddress;
