/**
 * @generated SignedSource<<6e195c95a013a755d8648212f180cf4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_certification_history_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerCertificationTableInsertHistoryMutation$variables = {
  objects: ReadonlyArray<worker_certification_history_insert_input>;
};
export type WorkerCertificationTableInsertHistoryMutation$data = {
  readonly insert_worker_certification_history: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type WorkerCertificationTableInsertHistoryMutation = {
  response: WorkerCertificationTableInsertHistoryMutation$data;
  variables: WorkerCertificationTableInsertHistoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "worker_certification_history_mutation_response",
    "kind": "LinkedField",
    "name": "insert_worker_certification_history",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerCertificationTableInsertHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerCertificationTableInsertHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7958da8b25147f1b5ce9a9ef2ecc0a9e",
    "id": null,
    "metadata": {},
    "name": "WorkerCertificationTableInsertHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerCertificationTableInsertHistoryMutation(\n  $objects: [worker_certification_history_insert_input!]!\n) {\n  insert_worker_certification_history(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1928d8b8cec52cb33e66496c9c62c46";

export default node;
