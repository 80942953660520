/**
 * @generated SignedSource<<f6dadd49e9b7dfe69bc8f260b62a5fe9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_plan_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation$variables = {
  object: safety_plan_insert_input;
};
export type SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation$data = {
  readonly insert_safety_plan_one: {
    readonly add_to_future_projects: boolean;
    readonly company_safety_plan_id: string | null | undefined;
    readonly created_at: string;
    readonly created_by_user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
    readonly id: string;
    readonly pk: string;
    readonly project_id: string | null | undefined;
    readonly revision_date: string | null | undefined;
    readonly revision_number: string | null | undefined;
    readonly title: string;
    readonly url: string;
  } | null | undefined;
};
export type SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation = {
  response: SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation$data;
  variables: SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revision_date",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revision_number",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_plan_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "add_to_future_projects",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "safety_plan",
        "kind": "LinkedField",
        "name": "insert_safety_plan_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "safety_plan",
        "kind": "LinkedField",
        "name": "insert_safety_plan_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d38ea265ca00886d3fa6a46e7195430",
    "id": null,
    "metadata": {},
    "name": "SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation",
    "operationKind": "mutation",
    "text": "mutation SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation(\n  $object: safety_plan_insert_input!\n) {\n  insert_safety_plan_one(object: $object) {\n    id\n    pk: id\n    title\n    revision_date\n    created_at\n    project_id\n    revision_number\n    url\n    company_safety_plan_id\n    add_to_future_projects\n    created_by_user {\n      id\n      pk: id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4a389dc3b8f5a4451a60960c583632f";

export default node;
