import { FC, useMemo } from "react";
import { Card } from "antd";
import {
  useUpdateInsuranceClaimMutation,
  Insurance_Claim_Set_Input,
  GetIncidentByPkQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentInputField from "../basic/InputField";
import IncidentSelectField from "../basic/SelectField";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import useAuthUser from "src/common/hooks/useAuthUser";
import { YES_NO_OPTIONS, getBooleanAsNumber } from "../basic/SelectField";

interface InsuranceDetailProps {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  injuredUserId: string;
}

const InsuranceDetail: FC<InsuranceDetailProps> = ({
  incident,
  injuredUserId,
}) => {
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const insuranceData = useMemo(() => {
    const incidentUserData = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    );
    return incidentUserData?.injury_detail?.insurance_claim;
  }, [incident.injured_users, injuredUserId]);

  const [updateInsuranceClaim, { loading: updateInsuranceClaimLoading }] =
    useUpdateInsuranceClaimMutation();
  const authUser = useAuthUser();

  const updateInsurance = async (
    key: keyof Insurance_Claim_Set_Input,
    value: string | number | boolean | null,
    comment: string,
  ) => {
    if (!insuranceData || !insuranceData.id) {
      throw new Error("insurance_detail is missing");
    }

    // console.log("........key..........", key, "........value..........", value);

    const updatedInjuredUsers = incident.injured_users.map((injuredUser) => {
      if (
        injuredUser.id === injuredUserId &&
        injuredUser.injury_detail &&
        injuredUser.injury_detail.insurance_claim
      ) {
        return {
          ...injuredUser,
          injury_detail: {
            ...injuredUser.injury_detail,
            insurance_claim: {
              ...injuredUser.injury_detail.insurance_claim,
              [key]: value,
            },
          },
        };
      } else return injuredUser;
    });
    const updatedIncident = { ...incident, injured_users: updatedInjuredUsers };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateInsuranceClaim({
      variables: {
        id: insuranceData.id,
        _set: { [key]: value },
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "insurance-detail-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_insurance_claim_by_pk: {
          ...insuranceData,
          [key]: value,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "insurance_detail",
      incidentUserId: injuredUserId,
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incident: incident,
      objects: objects,
      incidentFieldKey: "insurance_detail",
      incidentUserId: injuredUserId,
    });
  };

  const userName =
    incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    )?.user.name ?? "injured user";

  return (
    <div className="top-5 flex flex-col" key={insuranceData?.id}>
      <Card title="Insurance Claim Information">
        <div className="flex flex-col gap-1">
          <IncidentSelectField
            title="Add Insurance details"
            options={YES_NO_OPTIONS}
            value={getBooleanAsNumber(insuranceData?.insurance_added)}
            onChange={async (option) => {
              if (typeof option?.value === "number")
                await updateInsurance(
                  "insurance_added",
                  option.value === 1,
                  `Updated Insurance Detail for user ${userName} - "Add Insurance details" to "${option.label}"`,
                );
            }}
            loading={updateInsuranceClaimLoading}
          />

          {insuranceData?.insurance_added && (
            <>
              <IncidentInputField
                label="Last four of Employees SS#"
                onSave={(val) => {
                  updateInsurance(
                    "last_four_ss_number",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Last four of Employees SS#" to "${val}"  `,
                  );
                }}
                defaultText={insuranceData?.last_four_ss_number ?? ""}
              />

              <IncidentInputField
                label="Employer Insurance Policy Details"
                onSave={(val) => {
                  updateInsurance(
                    "employer_policy_number",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Employer Insurance Policy Details" to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.employer_policy_number ?? ""}
              />

              <IncidentInputField
                label="Current Weekly Wage"
                onSave={(val) => {
                  updateInsurance(
                    "current_weekly_wage",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Current Weekly Wage" to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.current_weekly_wage ?? ""}
              />

              <IncidentInputField
                label="Hourly Wage"
                onSave={(val) => {
                  updateInsurance(
                    "hourly_wage",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Hourly Wage" to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.hourly_wage ?? ""}
              />

              <IncidentInputField
                label="Hours worked per week"
                onSave={(val) => {
                  updateInsurance(
                    "hours_worked_per_week",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Hours worked per week" to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.hours_worked_per_week ?? ""}
              />

              <IncidentSelectField
                options={YES_NO_OPTIONS}
                title="Was Salary Continued"
                onChange={async (option) => {
                  if (option === null) {
                    await updateInsurance(
                      "salary_continued",
                      null,
                      `Removed "Was Salary Continued" from Insurance Detail for user ${userName}`,
                    );
                  } else if (typeof option.value === "number")
                    await updateInsurance(
                      "salary_continued",
                      option.value === 1,
                      `Updated Insurance Detail for user ${userName} - "Was Salary Continued" to "${option.label}"`,
                    );
                }}
                loading={updateInsuranceClaimLoading}
              />

              <IncidentInputField
                label="Employment Status"
                onSave={(val) => {
                  updateInsurance(
                    "employment_status",
                    val,
                    `Updated Insurance Detail for user ${userName} - "Employment Status"to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.employment_status ?? ""}
              />

              <IncidentSelectField
                options={YES_NO_OPTIONS}
                title="Was Employee Paid in Full on Date of Injury"
                onChange={async (option) => {
                  if (option === null) {
                    await updateInsurance(
                      "employee_paid_in_full",
                      null,
                      `Removed "Was Employee Paid in Full on Date of Injury" from Insurance Detail for user ${userName}`,
                    );
                  } else if (typeof option.value === "number")
                    await updateInsurance(
                      "employee_paid_in_full",
                      option.value === 1,
                      `Updated Insurance Detail for user ${userName} - "Was Employee Paid in Full on Date of Injury" to "${option.label}"`,
                    );
                }}
                value={getBooleanAsNumber(insuranceData?.employee_paid_in_full)}
                loading={updateInsuranceClaimLoading}
              />

              <IncidentSelectField
                options={YES_NO_OPTIONS}
                title="Has this EE been injured on this jobsite before?"
                onChange={async (option) => {
                  if (option === null) {
                    await updateInsurance(
                      "prior_workers_comp_claims",
                      null,
                      `Removed "Has this EE been injured on this jobsite before?" from Insurance Detail for user ${userName}`,
                    );
                  } else if (typeof option.value === "number")
                    await updateInsurance(
                      "prior_workers_comp_claims",
                      option.value === 1,
                      `Updated Insurance Detail for user ${userName} - "Has this EE been injured on this jobsite before?" to "${option.label}"`,
                    );
                }}
                value={getBooleanAsNumber(
                  insuranceData?.prior_workers_comp_claims,
                )}
                loading={updateInsuranceClaimLoading}
              />

              <IncidentInputField
                label="OSHA Reference #"
                onSave={(val) => {
                  updateInsurance(
                    "osha_reference_number",
                    val,
                    `Updated Insurance Detail for user ${userName} - "OSHA Reference #" to "${val}"`,
                  );
                }}
                defaultText={insuranceData?.osha_reference_number ?? ""}
              />

              <IncidentDocViewAndUpload
                deleteDocument={deleteDocument}
                documents={insuranceData.attached_files}
                groupId={insuranceData.id}
                uploadDocument={uploadDocument}
                type="incident"
              />
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default InsuranceDetail;
