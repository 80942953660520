/**
 * @generated SignedSource<<e4ff492a8bb39a832faa6123bff5db7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_employee_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddGCProjectTeamModal_insertProjectEmployee_Mutation$variables = {
  objects: ReadonlyArray<project_employee_insert_input>;
};
export type AddGCProjectTeamModal_insertProjectEmployee_Mutation$data = {
  readonly insert_project_employee: {
    readonly returning: ReadonlyArray<{
      readonly employee: {
        readonly employee_title: {
          readonly id: string;
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly name: string;
          readonly profile_picture: {
            readonly id: string;
            readonly url: string;
          } | null | undefined;
        };
      };
      readonly id: string;
      readonly pk: string;
      readonly status: string;
    }>;
  } | null | undefined;
};
export type AddGCProjectTeamModal_insertProjectEmployee_Mutation = {
  response: AddGCProjectTeamModal_insertProjectEmployee_Mutation$data;
  variables: AddGCProjectTeamModal_insertProjectEmployee_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "project_employee_project_id_employee_id_key",
      "update_columns": [
        "direct_project_assign"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "image",
      "kind": "LinkedField",
      "name": "profile_picture",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddGCProjectTeamModal_insertProjectEmployee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_employee_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddGCProjectTeamModal_insertProjectEmployee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_employee_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "756c590676e420f69d6ab8d99d1aa55b",
    "id": null,
    "metadata": {},
    "name": "AddGCProjectTeamModal_insertProjectEmployee_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddGCProjectTeamModal_insertProjectEmployee_Mutation(\n  $objects: [project_employee_insert_input!]!\n) {\n  insert_project_employee(objects: $objects, on_conflict: {constraint: project_employee_project_id_employee_id_key, update_columns: [direct_project_assign]}) {\n    returning {\n      id\n      pk: id\n      status\n      employee {\n        id\n        employee_title {\n          id\n          name {\n            en\n            id\n          }\n        }\n        user {\n          id\n          name\n          profile_picture {\n            id\n            url\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61ed7bf209a3829cae13cc7f0012d039";

export default node;
