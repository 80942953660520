const getColorAndCompleted = (
  isTypeAdded: boolean,
  reqFieldsComplete: boolean,
) => {
  if (!isTypeAdded) {
    return { className: "text-lightgrey", extraLabel: "" };
  } else if (reqFieldsComplete) {
    return {
      className: "text-semantic-positive-green",
      extraLabel: " (Complete)",
    };
  } else
    return { className: "text-semantic-negative", extraLabel: " (Incomplete)" };
};

export default getColorAndCompleted;
