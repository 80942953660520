/**
 * @generated SignedSource<<4032b80b0cfdda346a850681604dcd21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_worker_poc_bool_exp, project_worker_poc_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectWorkerPocTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<project_worker_poc_order_by>;
  where: project_worker_poc_bool_exp;
};
export type ProjectWorkerPocTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectWorkerPocTable_projectWorkerPoc">;
};
export type ProjectWorkerPocTableRefetchableQuery = {
  response: ProjectWorkerPocTableRefetchableQuery$data;
  variables: ProjectWorkerPocTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectWorkerPocTableRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProjectWorkerPocTable_projectWorkerPoc"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectWorkerPocTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_worker_pocConnection",
        "kind": "LinkedField",
        "name": "project_worker_poc_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker_pocEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker_poc",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone_number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "ProjectWorkerPocTable_project_worker_poc_connection",
        "kind": "LinkedHandle",
        "name": "project_worker_poc_connection"
      }
    ]
  },
  "params": {
    "cacheID": "3de483d6f400aeb97bb05b0db27aae6f",
    "id": null,
    "metadata": {},
    "name": "ProjectWorkerPocTableRefetchableQuery",
    "operationKind": "query",
    "text": "query ProjectWorkerPocTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $order_by: [project_worker_poc_order_by!]!\n  $where: project_worker_poc_bool_exp!\n) {\n  ...ProjectWorkerPocTable_projectWorkerPoc_4g04uc\n}\n\nfragment ProjectWorkerPocTable_projectWorkerPoc_4g04uc on query_root {\n  project_worker_poc_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        id\n        pk: id\n        title\n        name\n        phone_number\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6cc08fad62c82f55a15d3a942a6ce0d";

export default node;
