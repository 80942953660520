import { FC } from "react";
import {
  useUpdateEquipmentIncidentMutation,
  Equipment_Incident_Set_Input,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/TextField";
import IncidentSelectField from "../basic/SelectField";
import IncidentInputField from "../basic/InputField";
import dayjs from "dayjs";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import { useSearchParams } from "react-router-dom";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import IncidentSubViewLayout from "../IncidentSubViewLayout";
import IncidentMonetarySeverity from "../basic/IncidentMonetarySeverity";
import { YES_NO_OPTIONS, getBooleanAsNumber } from "../basic/SelectField";

const EquipmentIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();
  const authUser = useAuthUser();

  const [insertIncidentType, { loading: insertIncidentTypeLoading }] =
    useInsertIncidentType();
  const equipment = incident.equipment_incident;
  const documents = getIncidentDocuments(incident, "equipment");
  if (!equipment) {
    throw new Error("equipment_incident is missing");
  }

  const [updateEquipment, { loading: updateEquipmentLoading }] =
    useUpdateEquipmentIncidentMutation();
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "equipment");
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "equipment",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "equipment",
    });
  };

  const updateEquipmentIncident = async (
    _set: Omit<Equipment_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      equipment_incident: { ...equipment, _set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateEquipment({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "equipment-incident-update",
          comment: comment,
        },
      },
      optimisticResponse: {
        insert_incident_edit: {
          affected_rows: 1,
        },
        update_equipment_incident_by_pk: {
          ...equipment,
          incident_id: incident.id,
          ..._set,
        },
      },
    });
  };

  const operator = equipment.operator;

  return (
    <>
      <IncidentSubViewLayout title="Equipment">
        <div className="flex flex-col gap-1">
          <IncidentCommonUser
            title="Operator"
            user={equipment.operator ?? undefined}
            onUpdateUser={async (id, name) => {
              updateEquipmentIncident(
                { operator_user_id: id },
                `Updated Equipment - "Operator" to ${name}`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!operator) {
                const updatedIncident = {
                  ...incident,
                  equipment_incident: {
                    ...equipment,
                    operator: {
                      ...operator,
                      [key]: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated Equipment - ${label} of the "Operator" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (operator && operator.general_person) {
                const updatedIncident = {
                  ...incident,
                  equipment_incident: {
                    ...equipment,
                    operator: {
                      ...operator,
                      general_person: {
                        ...operator.general_person,
                        employer: val,
                      },
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated Equipment - employer of the "Reported by user" to ${val}`;
                updateGeneralPerson(
                  operator.id,
                  { employer: val },
                  comment,
                  incident.id,
                  patch,
                );
              }
            }}
            projectId={incident.project_id}
          />
          <Card>
            <IncidentInputField
              label="Equipment / Vehicle Number"
              defaultText={equipment.equipment_number ?? undefined}
              onSave={(val) => {
                updateEquipmentIncident(
                  { equipment_number: val },
                  `Updated Equipment - "Equipment / Vehicle Number" to ${val}`,
                );
              }}
            />

            <IncidentSelectField
              options={YES_NO_OPTIONS}
              onChange={async (option) => {
                if (option === null) {
                  await updateEquipmentIncident(
                    {
                      rental_chosen: null,
                    },
                    `Removed "Is the equipment rented, owned, or rented by another subcontractor?" from Equipment Detail`,
                  );
                } else if (typeof option.value === "number")
                  await updateEquipmentIncident(
                    {
                      rental_chosen: option.value === 1,
                    },
                    `Updated Equipment - "Is the equipment rented, owned, or rented by another subcontractor?" to ${option.label}`,
                  );
              }}
              title="Is the equipment rented, owned, or rented by another subcontractor?"
              value={getBooleanAsNumber(equipment.rental_chosen)}
              loading={insertIncidentTypeLoading || updateEquipmentLoading}
            />

            {equipment.rental_chosen && (
              <IncidentTextField
                label="Rented From"
                text={equipment.rented_from.en}
                textId={equipment.rented_from.id}
                autoSize={{ minRows: 1, maxRows: 10 }}
                saveIncidentField={insertIncidentTypeLink}
                fieldTypeKey="equipment"
                field="rented_from"
                incident={incident}
              />
            )}

            {equipment.rental_chosen && (
              <IncidentInputField
                label="Rental Company Phone Number"
                onSave={(val) => {
                  updateEquipmentIncident(
                    { rental_company_phone_number: val },
                    `Updated Equipment - "Rental Company Phone Number" to ${val}`,
                  );
                }}
                defaultText={equipment.rental_company_phone_number ?? undefined}
              />
            )}
          </Card>

          <Card>
            <IncidentMonetarySeverity
              incident={incident}
              incidentType="Equipment"
            />

            <IncidentInputField
              label="Estimated damage (in USD)"
              validateAsNumber={true}
              defaultText={String(equipment.estimated_damage ?? "")}
              onSave={(val) => {
                if (val === "") return;
                const damage = parseInt(val);
                updateEquipmentIncident(
                  { estimated_damage: damage },
                  `Updated Equipment - "Estimated damage" to ${val}`,
                );
              }}
            />

            <IncidentInputField
              label="Police Report Number"
              defaultText={equipment.report_number ?? undefined}
              onSave={(val) => {
                updateEquipmentIncident(
                  { report_number: val },
                  `Updated Equipment - "Police Report Number" to ${val}`,
                );
              }}
            />
          </Card>

          <Card>
            <IncidentSelectField
              title="Did operator violate any safety rules"
              options={YES_NO_OPTIONS}
              onChange={async (option) => {
                if (option === null) {
                  await updateEquipmentIncident(
                    {
                      operator_violated_safety_rules: null,
                    },
                    `Removed "Did operator violate any safety rules" from Equipment Detail`,
                  );
                } else if (typeof option.value === "number")
                  await updateEquipmentIncident(
                    {
                      operator_violated_safety_rules: option.value === 1,
                    },

                    `Updated Equipment - "Did operator violate any safety rules" to ${option.label}`,
                  );
              }}
              value={getBooleanAsNumber(
                equipment.operator_violated_safety_rules,
              )}
              loading={insertIncidentTypeLoading || updateEquipmentLoading}
            />

            {equipment.operator_violated_safety_rules && (
              <IncidentTextField
                required={true}
                label="Rules Violated"
                text={equipment.rules_violated.en}
                textId={equipment.rules_violated.id}
                saveIncidentField={insertIncidentTypeLink}
                fieldTypeKey="equipment"
                field="rules_voilated"
                incident={incident}
              />
            )}
          </Card>
          <Card>
            <IncidentSelectField
              title="Was there property damage"
              required={true}
              options={YES_NO_OPTIONS}
              onChange={async (option) => {
                if (option === null) {
                  await updateEquipmentIncident(
                    { property_damaged: null },
                    `Removed "Was there property damage" from Equipment Detail`,
                  );
                } else if (typeof option.value === "number")
                  await updateEquipmentIncident(
                    { property_damaged: option.value === 1 },
                    `Updated Equipment - "Was there property damage"to ${option.label}`,
                  );
              }}
              value={getBooleanAsNumber(equipment.property_damaged)}
              loading={insertIncidentTypeLoading || updateEquipmentLoading}
            />

            {equipment.property_damaged && (
              <IncidentTextField
                required={true}
                label="Details of Damage"
                text={equipment.damage_detail.en}
                textId={equipment.damage_detail.id}
                saveIncidentField={insertIncidentTypeLink}
                fieldTypeKey="equipment"
                field="damage_details"
                incident={incident}
              />
            )}
          </Card>

          {incident.incident_types.findIndex(
            (indexType) => indexType.type_value === "equipment",
          ) !== -1 && (
            <Card>
              <IncidentDocViewAndUpload
                deleteDocument={deleteDocument}
                documents={documents}
                groupId={incident.id}
                uploadDocument={uploadDocument}
                type="equipment"
              />
            </Card>
          )}

          <IncidentBottomButtons
            saveDisabled={
              !equipment.damage_detail.en ||
              (equipment.operator_violated_safety_rules === true &&
                !equipment.rules_violated)
            }
            onNextClick={() => {
              const next = getNextPage(incident, "equipment");
              setSearchParams({ subview: next });
            }}
            onSave={() => {
              updateEquipmentIncident(
                { completed_at: dayjs().format() },
                `Equipment section marked as completed`,
              );
            }}
          />
        </div>
      </IncidentSubViewLayout>
    </>
  );
};

export default EquipmentIncident;
