/**
 * @generated SignedSource<<e93aafc08073af010d88a3b940820ab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_crew_bool_exp, project_crew_insert_input, project_worker_bool_exp, project_worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpsertCrewDataMutation$variables = {
  newCrewObject: project_crew_insert_input;
  prevLeadsProjectCrewWhere: project_crew_bool_exp;
  projectWorkerWhere: project_worker_bool_exp;
  setProjectWorkers?: project_worker_set_input | null | undefined;
};
export type useUpsertCrewDataMutation$data = {
  readonly insert_project_crew_one: {
    readonly crew_onsite_periods: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly deleted_at: string | null | undefined;
    readonly id: string;
    readonly lead_foreman_project_worker: {
      readonly id: string;
      readonly pk: string;
      readonly subcontractor_worker: {
        readonly id: string;
      } | null | undefined;
      readonly user: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly role: string;
        readonly username: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly name: string;
    readonly pk: string;
    readonly project: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
    readonly project_workers: ReadonlyArray<{
      readonly hard_hat_number: string | null | undefined;
      readonly id: string;
      readonly pk: string;
      readonly title: {
        readonly translation: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
      } | null | undefined;
      readonly user: {
        readonly email: string | null | undefined;
        readonly name: string;
      } | null | undefined;
      readonly worker_role: string;
    }>;
    readonly project_workers_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
    readonly subcontractor: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
    readonly subcontractor_id: string;
  } | null | undefined;
  readonly update_project_crew: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_worker: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly leading_project_crews_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly project_crew: {
        readonly id: string;
        readonly lead_foreman_project_worker: {
          readonly id: string;
          readonly pk: string;
          readonly user: {
            readonly name: string;
          } | null | undefined;
        } | null | undefined;
        readonly name: string;
        readonly pk: string;
      } | null | undefined;
    }>;
  } | null | undefined;
};
export type useUpsertCrewDataMutation = {
  response: useUpsertCrewDataMutation$data;
  variables: useUpsertCrewDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newCrewObject"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prevLeadsProjectCrewWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectWorkerWhere"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "setProjectWorkers"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "_set",
      "value": {
        "lead_foreman_project_worker_id": null
      }
    },
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "prevLeadsProjectCrewWhere"
    }
  ],
  "concreteType": "project_crew_mutation_response",
  "kind": "LinkedField",
  "name": "update_project_crew",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "newCrewObject"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "subcontractor_worker": {}
    }
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_role",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v20 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v21 = {
  "_is_null": true
},
v22 = [
  (v7/*: any*/)
],
v23 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "close_date": (v21/*: any*/)
      }
    }
  ],
  "concreteType": "crew_onsite_period",
  "kind": "LinkedField",
  "name": "crew_onsite_periods",
  "plural": true,
  "selections": (v22/*: any*/),
  "storageKey": "crew_onsite_periods(where:{\"close_date\":{\"_is_null\":true}})"
},
v24 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "deleted_at": (v21/*: any*/),
        "subcontractor_worker": {}
      }
    }
  ],
  "concreteType": "project_worker_aggregate",
  "kind": "LinkedField",
  "name": "project_workers_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "project_worker_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"subcontractor_worker\":{}})"
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "worker",
  "kind": "LinkedField",
  "name": "subcontractor_worker",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v29 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "setProjectWorkers"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "projectWorkerWhere"
  }
],
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "project_crew_aggregate",
  "kind": "LinkedField",
  "name": "leading_project_crews_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "project_crew_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v32 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v33 = [
  (v7/*: any*/),
  (v31/*: any*/),
  (v32/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpsertCrewDataMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "project_crew",
        "kind": "LinkedField",
        "name": "insert_project_crew_one",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "project_workers",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_title",
                "kind": "LinkedField",
                "name": "title",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "translation",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__original_clientText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
          },
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "subcontractor",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          (v23/*: any*/),
          (v25/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "lead_foreman_project_worker",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v26/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v29/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v30/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_crew",
                "kind": "LinkedField",
                "name": "project_crew",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_worker",
                    "kind": "LinkedField",
                    "name": "lead_foreman_project_worker",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpsertCrewDataMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "project_crew",
        "kind": "LinkedField",
        "name": "insert_project_crew_one",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "project_workers",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_title",
                "kind": "LinkedField",
                "name": "title",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "translation",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v18/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
          },
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "subcontractor",
            "plural": false,
            "selections": (v33/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v33/*: any*/),
            "storageKey": null
          },
          (v23/*: any*/),
          (v25/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "lead_foreman_project_worker",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v26/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v9/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v29/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v30/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_crew",
                "kind": "LinkedField",
                "name": "project_crew",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_worker",
                    "kind": "LinkedField",
                    "name": "lead_foreman_project_worker",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "595f39f75966532178da531c06b5dd8c",
    "id": null,
    "metadata": {},
    "name": "useUpsertCrewDataMutation",
    "operationKind": "mutation",
    "text": "mutation useUpsertCrewDataMutation(\n  $projectWorkerWhere: project_worker_bool_exp!\n  $prevLeadsProjectCrewWhere: project_crew_bool_exp!\n  $setProjectWorkers: project_worker_set_input\n  $newCrewObject: project_crew_insert_input!\n) {\n  update_project_crew(where: $prevLeadsProjectCrewWhere, _set: {lead_foreman_project_worker_id: null}) {\n    affected_rows\n  }\n  insert_project_crew_one(object: $newCrewObject) {\n    id\n    pk: id\n    name\n    deleted_at\n    project_workers(where: {subcontractor_worker: {}}, order_by: {user: {name: asc}}) {\n      id\n      pk: id\n      worker_role\n      hard_hat_number\n      title {\n        translation {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        id\n      }\n      user {\n        name\n        email\n        id\n      }\n    }\n    subcontractor_id\n    subcontractor {\n      id\n      pk: id\n      name\n    }\n    project {\n      id\n      pk: id\n      name\n    }\n    crew_onsite_periods(where: {close_date: {_is_null: true}}) {\n      id\n    }\n    project_workers_aggregate(where: {subcontractor_worker: {}, deleted_at: {_is_null: true}}) {\n      aggregate {\n        count\n      }\n    }\n    lead_foreman_project_worker {\n      id\n      subcontractor_worker {\n        id\n      }\n      pk: id\n      user {\n        id\n        pk: id\n        name\n        username\n        role\n      }\n    }\n  }\n  update_project_worker(where: $projectWorkerWhere, _set: $setProjectWorkers) {\n    affected_rows\n    returning {\n      id\n      leading_project_crews_aggregate {\n        aggregate {\n          count\n        }\n      }\n      project_crew {\n        id\n        pk: id\n        name\n        lead_foreman_project_worker {\n          id\n          pk: id\n          user {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7d2b58da2502753a7d2d785e61809d5";

export default node;
