/**
 * @generated SignedSource<<b3be32fd37a41864099b22848c3055f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_certification_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation$variables = {
  objects: ReadonlyArray<project_certification_insert_input>;
};
export type SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation$data = {
  readonly insert_project_certification: {
    readonly returning: ReadonlyArray<{
      readonly alternate_certifications: ReadonlyArray<{
        readonly alternate_certification: {
          readonly name: string;
        } | null | undefined;
      }>;
      readonly certification: {
        readonly name: string;
      };
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation = {
  response: SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation$data;
  variables: SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project_alternate_certification",
                "kind": "LinkedField",
                "name": "alternate_certifications",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "certification",
                    "kind": "LinkedField",
                    "name": "alternate_certification",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project_alternate_certification",
                "kind": "LinkedField",
                "name": "alternate_certifications",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "certification",
                    "kind": "LinkedField",
                    "name": "alternate_certification",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "326de234581b0575328ef6c8fc139ea7",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSettingSwitchCards_Insert_Certificates_Mutation(\n  $objects: [project_certification_insert_input!]!\n) {\n  insert_project_certification(objects: $objects) {\n    returning {\n      id\n      certification {\n        name\n        id\n      }\n      alternate_certifications {\n        alternate_certification {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e96ef2bac18bbf3c1962855a73401006";

export default node;
