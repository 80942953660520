import * as React from "react";
import GCWorkersTable, {
  Subcontractor,
} from "src/common/components/tables/GCWorkersTable";
import { useGetSubcontractorsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { auth } from "src/common/functions/firebase";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../hierarchy/corporate-office/withCorporateAdminWrapper";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";

const GCEmployeeVerification: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  if (!employee.general_contractor.employee_verification)
    throw new NotifyUserException(
      "This feature isn't enabled for you, please contact SiteForm Admin if you have any queries",
    );
  const userId = employee.uid;
  const { data, loading, error } = useGetSubcontractorsQuery({
    variables: {
      where: {
        project_workers: {
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          archived_at: { _is_null: true },
          project: {
            project_employees: {
              employee: {
                user: { id: { _eq: userId } },
                general_contractor: { employee_verification: { _eq: true } },
              },
            },
          },
        },
      },
    },
  });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data) {
    throw Error(
      error?.message ?? "An Error occurred while fetching subcontractors",
    );
  }

  const subContractorFilterOptions = data.subcontractor.map(
    (subcontractor): Subcontractor => {
      return {
        id: subcontractor.id,
        name: subcontractor.name,
      };
    },
  );

  return (
    <GCWorkersTable
      userId={userId}
      title="Employee Verification"
      subContractorFilterOptions={subContractorFilterOptions}
      where={{
        is_last: { _eq: true },
        deleted_at: { _is_null: true },
        archived_at: { _is_null: true },
        project: {
          project_employees: {
            employee: {
              user: { id: { _eq: userId } },
              general_contractor: { employee_verification: { _eq: true } },
            },
          },
        },
      }}
    ></GCWorkersTable>
  );
};

export default withCustomSuspense(
  withCorporateAdminWrapper(GCEmployeeVerification),
);
