/**
 * @generated SignedSource<<d9f719bc2846b3a24e4dfa6a09b09316>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { uploaded_toolbox_talk_project_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadedToolboxTalkTable_AddToAnotherProject_Mutation$variables = {
  objects: ReadonlyArray<uploaded_toolbox_talk_project_insert_input>;
};
export type UploadedToolboxTalkTable_AddToAnotherProject_Mutation$data = {
  readonly insert_uploaded_toolbox_talk_project: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type UploadedToolboxTalkTable_AddToAnotherProject_Mutation = {
  response: UploadedToolboxTalkTable_AddToAnotherProject_Mutation$data;
  variables: UploadedToolboxTalkTable_AddToAnotherProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "uploaded_toolbox_talk_project_mutation_response",
    "kind": "LinkedField",
    "name": "insert_uploaded_toolbox_talk_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "uploaded_toolbox_talk_project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalkTable_AddToAnotherProject_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalkTable_AddToAnotherProject_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1685e23a07b0204e50fa3e4ac6fc2ee3",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalkTable_AddToAnotherProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation UploadedToolboxTalkTable_AddToAnotherProject_Mutation(\n  $objects: [uploaded_toolbox_talk_project_insert_input!]!\n) {\n  insert_uploaded_toolbox_talk_project(objects: $objects) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5363f7ed2002899b4ad1bf3e23fc8348";

export default node;
