import { FC, useMemo } from "react";
import { Menu, Spin } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  GetIncidentByPkDocument,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  useEmailIncidentPdfMutation,
  useGenerateIncidentPdfMutation,
  useGetMonetarySeverityLevelsQuery,
  useUpdateIncidentMutation,
  useUpdateIncidentUserMutation,
  useGetIncidentByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import getMenuItem from "../../utils/getMenuItem";
import { MaterialSymbol } from "react-material-symbols";
import dayjs from "dayjs";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import getGcProjectPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";
import getGcCorporateOfficePath from "../../../../root/routes/views/general-contractor/hierarchy/corporate-office/utils/getGcCorporateOfficePath";
import { IncidentType } from "../../utils/getMenuItem";
import IncidentComponentRenderer from "../../components/IncidentComponentRenderer";

const GcIncidentManagement: FC = () => {
  const { projectId, incidentId } = useParams();
  if (!incidentId) throw new Error("incidentId not found");
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const subview = searchParams.get("subview");
  const incidentUserId = searchParams.get("id");
  const [emailIncidentPdf, { loading: emailing }] =
    useEmailIncidentPdfMutation();
  const [generateIncidentPdf, { loading: generating }] =
    useGenerateIncidentPdfMutation();
  const [updateIncident] = useUpdateIncidentMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const { loading: monetaryLoading, error: monetaryError } =
    useGetMonetarySeverityLevelsQuery(); // used in child component queries

  const {
    data: incidentData,
    refetch,
    loading: incidentLoading,
  } = useGetIncidentByPkQuery({
    variables: { incidentId: incidentId },
  });

  const incident = incidentData?.incident_by_pk;
  const allIncidentTypes: Array<IncidentType> = [
    "environmental",
    "equipment",
    "near_miss",
    "property_damage",
    "vehicle",
    "utility",
    "theft",
  ];

  const getIncidentType: (type: string) => IncidentType | null = (type) => {
    const index = allIncidentTypes.findIndex(
      (incidentType) => incidentType === type,
    );
    if (index !== -1) {
      return allIncidentTypes[index];
    }
    return null;
  };

  const { selectedIncidentTypes, nonSelectedIncidentTypes } = useMemo(() => {
    const selectedIncidentTypes: Array<string> = [];
    const nonSelectedIncidentTypes: Array<string> = [];
    for (const type of allIncidentTypes) {
      if (
        incident?.incident_types.findIndex(
          (incidentType) => incidentType.type_value === type,
        ) !== -1
      ) {
        selectedIncidentTypes.push(type);
      } else {
        nonSelectedIncidentTypes.push(type);
      }
    }
    return { selectedIncidentTypes, nonSelectedIncidentTypes };
  }, [incident?.incident_types]);

  if (!incident) return <Spin />;

  const deleteIncidentUser = async (
    id: string,
    type: "witness" | "injured_user",
  ) => {
    const incidentUsers =
      type === "witness" ? incident.witnesses : incident.injured_users;

    const userName = incidentUsers.find(
      (incidentUser) => incidentUser.id === id,
    )?.user.name;
    const filteredIncidetUsers = incidentUsers.filter(
      (incidentUser) => incidentUser.id !== id,
    );

    const updatedIncident =
      type === "injured_user"
        ? {
            ...incident,
            injured_users: filteredIncidetUsers,
          }
        : { ...incident, witnesses: filteredIncidetUsers };

    const patch = createIncidentPatch(updatedIncident, incident);

    const comment = `Deleted the ${
      type === "injured_user" ? "injured user" : "witness"
    } ${userName}`;

    await updateIncidentUser({
      variables: {
        id: id,
        set: { deleted_at: dayjs().format() },
        objects: {
          patch: patch,
          comment: comment,
          edit_type: "deleted-incident-user",
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const incidentUserId = result.data?.update_incident_user_by_pk?.id;
        if (!incidentUserId) return;

        const incidentUsers =
          type === "witness" ? incident.witnesses : incident.injured_users;

        const filteredIncidetUsers = incidentUsers.filter(
          (incidentUser) => incidentUser.id !== incidentUserId,
        );
        const updatedIncident =
          type === "injured_user"
            ? {
                ...incident,
                injured_users: filteredIncidetUsers,
              }
            : { ...incident, witnesses: filteredIncidetUsers };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });

    const incidentUserId = searchParams.get("id");

    if (incidentUserId === id) {
      setSearchParams({ subview: "incident_detail" });
    }
  };

  const selectedItem = incidentUserId
    ? `${subview}&&${incidentUserId}`
    : subview;

  const incidentUsersData: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  const witnessUsers: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  if (!!incident?.injured_users && Array.isArray(incident.injured_users)) {
    for (const user of incident.injured_users) {
      incidentUsersData.push({
        key: `injured_user&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "injured_user")}
                okText="Yes"
                cancelText="No"
              >
                <MaterialSymbol icon="delete" />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  if (!!incident.witnesses && Array.isArray(incident.witnesses)) {
    for (const user of incident.witnesses) {
      witnessUsers.push({
        key: `witness&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "witness")}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  icon={<MaterialSymbol icon="delete" />}
                  color="negative"
                />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  incidentUsersData.push({
    key: "injured_user",
    label: <span>+ Add Person</span>,
  });
  witnessUsers.push({ key: "witness", label: <span> + Add Person </span> });

  const menuItems = [
    {
      ...getMenuItem(incident, "project"),
      key: "project_info",
    },
    {
      ...getMenuItem(incident, "incident_detail"),
      key: "incident_detail",
    },
    {
      ...getMenuItem(incident, "injury_illness"),
      key: "injury_detail",
      children: incidentUsersData,
    },
    {
      ...getMenuItem(incident, "root_cause"),
      key: "root_cause",
    },
    {
      ...getMenuItem(incident, "witness"),
      key: "witness_detail",
      children: witnessUsers,
    },
  ];

  for (const type of selectedIncidentTypes) {
    const incidentType = getIncidentType(type);
    if (incidentType) {
      menuItems.push({
        ...getMenuItem(incident, incidentType),
        key: type,
      });
    }
  }

  for (const type of nonSelectedIncidentTypes) {
    const incidentType = getIncidentType(type);
    if (incidentType) {
      menuItems.push({
        ...getMenuItem(incident, incidentType),
        key: type,
      });
    }
  }

  menuItems.push({
    ...getMenuItem(incident, "notes"),
    key: "notes",
  });

  menuItems.push({
    ...getMenuItem(incident, "logs"),
    key: "logs",
  });

  if (monetaryLoading) return <Spin />;
  if (monetaryError) throw monetaryError;
  return (
    <div className="flex flex-col w-full gap-1">
      <div className={`flex relative flex-row gap-1 w-full`}>
        <div className={``}>
          <Menu
            mode="inline"
            className={`sticky top-0`}
            selectedKeys={[selectedItem ?? ""]}
            items={menuItems}
            onClick={(e) => {
              if (e.key === "table") {
                navigate(
                  projectId
                    ? getGcProjectPath(projectId, "incidents")
                    : getGcCorporateOfficePath("incidents"),
                );
              } else {
                const [type, id] = e.key.split("&&");
                setSearchParams(
                  id ? { subview: type, id: id } : { subview: type },
                );
              }
            }}
          />
        </div>

        <div className={`flex-1`}>
          <IncidentComponentRenderer
            selectedIncidentUserKey={incidentUserId}
            subview={subview}
            typeProps={{ incident, refetch }}
          />
        </div>
      </div>
    </div>
  );
};

export default withCustomSuspense(GcIncidentManagement);
