/**
 * @generated SignedSource<<45c36fcfc53f661f29ba24efa704b251>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { crew_onsite_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation$variables = {
  object: crew_onsite_period_insert_input;
};
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation$data = {
  readonly insert_crew_onsite_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation = {
  response: usePermanentOnsiteSettingInsertCrewOnsiteMutation$data;
  variables: usePermanentOnsiteSettingInsertCrewOnsiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "crew_onsite_period_project_crew_id_start_date_key",
          "update_columns": [
            "close_date"
          ]
        }
      }
    ],
    "concreteType": "crew_onsite_period",
    "kind": "LinkedField",
    "name": "insert_crew_onsite_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc6ac00e1c246b9d3bda016609c427ca",
    "id": null,
    "metadata": {},
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentOnsiteSettingInsertCrewOnsiteMutation(\n  $object: crew_onsite_period_insert_input!\n) {\n  insert_crew_onsite_period_one(object: $object, on_conflict: {constraint: crew_onsite_period_project_crew_id_start_date_key, update_columns: [close_date]}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cec87254198b833de99f1ed1423777f1";

export default node;
