import AppPageLayout from "src/common/components/layouts/main/content/AppPageLayout";
import getGcCorporateOfficePath from "../utils/getGcCorporateOfficePath";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "src/common/components/layouts/tabs/CustomTabsRouted";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import { Outlet } from "react-router-dom";

const GCCorporateIncidentTabs: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const gc = employee.general_contractor;
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "workers",
      label: "Workers",
    },
    {
      path: "worker-verification",
      label: "Worker Verifications",
    },
  ];
  const basePath = getGcCorporateOfficePath("workers");
  return (
    <AppPageLayout pageTitle={"Workers"}>
      {!gc.employee_verification ? (
        <Outlet />
      ) : (
        <CustomTabsRouted tabs={tabs} basePath={basePath} />
      )}
    </AppPageLayout>
  );
};
export default withCorporateAdminWrapper(GCCorporateIncidentTabs);
