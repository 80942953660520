/**
 * @generated SignedSource<<e89b199b09c808eef6ffa21558b8f50f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { gc_disabled_project_subcontractor_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GCDisabledProjectSubcontractorTableTotalRefetchableQuery$variables = {
  where: gc_disabled_project_subcontractor_bool_exp;
};
export type GCDisabledProjectSubcontractorTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GCDisabledProjectSubcontractorTable_total">;
};
export type GCDisabledProjectSubcontractorTableTotalRefetchableQuery = {
  response: GCDisabledProjectSubcontractorTableTotalRefetchableQuery$data;
  variables: GCDisabledProjectSubcontractorTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCDisabledProjectSubcontractorTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GCDisabledProjectSubcontractorTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCDisabledProjectSubcontractorTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allSubsConnection",
        "args": (v1/*: any*/),
        "concreteType": "gc_disabled_project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "gc_disabled_project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "gc_disabled_project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "gc_disabled_project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a249c122ac29fd68adfb40fe5ca9d28e",
    "id": null,
    "metadata": {},
    "name": "GCDisabledProjectSubcontractorTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query GCDisabledProjectSubcontractorTableTotalRefetchableQuery(\n  $where: gc_disabled_project_subcontractor_bool_exp!\n) {\n  ...GCDisabledProjectSubcontractorTable_total_3FC4Qo\n}\n\nfragment GCDisabledProjectSubcontractorTable_total_3FC4Qo on query_root {\n  allSubsConnection: gc_disabled_project_subcontractor_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b954249600447daabd79e671461e82df";

export default node;
