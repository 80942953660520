/**
 * @generated SignedSource<<2a6f6e1adc64b79117423b05054095be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_delivery_block_out_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useBlockOutDeliveryTimesInsertBlockOutMutation$variables = {
  object: project_delivery_block_out_insert_input;
};
export type useBlockOutDeliveryTimesInsertBlockOutMutation$data = {
  readonly insert_project_delivery_block_out_one: {
    readonly end_time: string;
    readonly id: string;
    readonly start_time: string;
    readonly weekday: number;
  } | null | undefined;
};
export type useBlockOutDeliveryTimesInsertBlockOutMutation = {
  response: useBlockOutDeliveryTimesInsertBlockOutMutation$data;
  variables: useBlockOutDeliveryTimesInsertBlockOutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "project_delivery_block_out",
    "kind": "LinkedField",
    "name": "insert_project_delivery_block_out_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start_time",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end_time",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weekday",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useBlockOutDeliveryTimesInsertBlockOutMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBlockOutDeliveryTimesInsertBlockOutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "142edb942b72bd736135d6b4664730dc",
    "id": null,
    "metadata": {},
    "name": "useBlockOutDeliveryTimesInsertBlockOutMutation",
    "operationKind": "mutation",
    "text": "mutation useBlockOutDeliveryTimesInsertBlockOutMutation(\n  $object: project_delivery_block_out_insert_input!\n) {\n  insert_project_delivery_block_out_one(object: $object) {\n    id\n    start_time\n    end_time\n    weekday\n  }\n}\n"
  }
};
})();

(node as any).hash = "00ade5ba1cb2ec5a2c60435c6cd76967";

export default node;
