import { FC } from "react";
import {
  Document_Insert_Input,
  Incident_Set_Input,
  useUpdateIncidentMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import IncidentTextField from "./basic/TextField";
import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import IncidentCommonUser from "./incident-users/IncidentCommonUser";
import IncidentDatepicker from "./basic/Datepicker";
import IncidentSelectField, {
  getBooleanAsNumber,
  YES_NO_OPTIONS,
} from "./basic/SelectField";
import IncidentBottomButtons from "./basic/IncidentBottomButtons";
import IncidentDocViewAndUpload from "./basic/IncidentDocViewAndUpload";
import IncidentWeatherObservation from "./IncidentWeatherObservation";
import IncidentTaskUploader from "./IncidentTaskUploader";
import { DeletedDocument } from "./basic/IncidentDocViewer";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useUploadIncidentDocuments from "../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../utils/useDeleteIncidentDocument";
import IncidentSubViewLayout from "./IncidentSubViewLayout";

const IncidentDetail: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [updateIncident, { loading: updateIncidentLoading }] =
    useUpdateIncidentMutation();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const updateIncidentFields = async (
    _set: Omit<
      Incident_Set_Input,
      "id" | "project_id" | "created_at" | "description_id"
    >,
    comment: string,
  ) => {
    const updatedIncident = { ...incident, ..._set };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: [
          {
            patch: patch,
            edited_by_uid: authUser.uid,
            incident_id: incident.id,
            edit_type: "incident-detail-edit",
            comment: comment,
          },
        ],
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          ..._set,
          id: incident.id,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_detail",
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_detail",
      incident: incident,
      objects: objects,
    });
  };

  const supervisor = incident.supervisor_user;
  const reportedByUser = incident.reported_by_user;
  const lightingConditions = [
    { key: "well_lit", value: "well_lit", label: "Well lit (no impact)" },
    { key: "dimly_lit", value: "dimly_lit", label: "Dimly lit (no impact)" },
    {
      key: "poor_lighting",
      value: "poor_lighting",
      label: "Poor lighting (impact)",
    },
  ];
  const saveEnabled =
    !!incident?.lighting_conditions &&
    !!incident.summary &&
    !!incident.description.en &&
    !!incident.incident_time &&
    ((incident.emergency_service_called && !!incident.emergency_service) ||
      !incident.emergency_service_called);
  return (
    <IncidentSubViewLayout title="Incident Details">
      <div className="flex flex-col gap-1">
        <Card>
          <IncidentTextField
            required={true}
            label="Incident title"
            text={incident.summary.en}
            textId={incident.summary.id}
            autoSize={{ minRows: 1, maxRows: 2 }}
            fieldTypeKey="incident_detail"
            field="summary"
            incident={incident}
          />

          <IncidentTextField
            required={true}
            label="Full description and details of incident"
            text={incident.description.en}
            textId={incident.description.id}
            autoSize={{ minRows: 5, maxRows: 10 }}
            fieldTypeKey="incident_detail"
            field="description"
            incident={incident}
          />

          <IncidentTextField
            label="Location of Incident"
            text={incident.location.en}
            textId={incident.location.id}
            fieldTypeKey="incident_detail"
            field="location"
            incident={incident}
          />

          <IncidentDatepicker
            required={true}
            format="YYYY-MM-DD h:mm A"
            label="Date and Time of Incident"
            onChange={async (val) => {
              await updateIncidentFields(
                { incident_time: val },
                `Updated Incident Detail - "Time of incident" to "${dayjs(
                  val,
                ).format("DD MMM, YYYY h:mm A")}"`,
              );
            }}
            showTime
            value={incident.incident_time ?? undefined}
            disabled={updateIncidentLoading}
          />
          <IncidentDatepicker
            format="YYYY-MM-DD h:mm A"
            label="Date and Time Incident was Reported"
            onChange={async (val) => {
              await updateIncidentFields(
                { reporting_time: val },
                `Updated Incident Detail - "Reporting time" to "${dayjs(
                  val,
                ).format("DD MMM, YYYY h:mm A")}"`,
              );
            }}
            showTime
            value={incident.reporting_time ?? undefined}
            disabled={updateIncidentLoading}
          />
        </Card>

        <IncidentCommonUser
          title="Who Reported the Incident"
          user={incident?.reported_by_user ?? undefined}
          onUpdateUser={async (id, name) => {
            updateIncidentFields(
              { reported_by_uid: id },
              `Updated Incident Detail - "Reported by user" to "${name}"`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (!!reportedByUser) {
              const updatedIncident = {
                ...incident,
                reported_by_user: { ...reportedByUser, [key]: val },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated ${label} of the "Reported by user" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (reportedByUser && reportedByUser.general_person) {
              const updatedIncident = {
                ...incident,
                reported_by_user: {
                  ...reportedByUser,
                  general_person: {
                    ...reportedByUser.general_person,
                    employer: val,
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated employer of the "Reported by user" to ${val}`;
              updateGeneralPerson(
                reportedByUser.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          projectId={incident.project_id}
        />

        <IncidentCommonUser
          title=" Supervisor of the Work when Incident occured   "
          user={supervisor ?? undefined}
          onUpdateUser={async (id, name) => {
            updateIncidentFields(
              { supervisor_user_id: id },
              `Updated Incident Detail - "Supervisor of Work" to "${name}"`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (!!supervisor) {
              const updatedIncident = {
                ...incident,
                supervisor_user: { ...supervisor, [key]: val },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated ${label} of the "Supervisor of work" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (supervisor && supervisor.general_person) {
              const updatedIncident = {
                ...incident,
                supervisor_user: {
                  ...supervisor,
                  general_person: {
                    ...supervisor.general_person,
                    employer: val,
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated employer of the "Reported by user" to ${val}`;
              updateGeneralPerson(
                supervisor.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          projectId={incident.project_id}
        />
        <Card title="JHA Being Performed">
          <IncidentTaskUploader incident={incident} />
        </Card>

        <Card>
          <IncidentSelectField
            title={"Was a post incident drug & alcohol test performed"}
            options={YES_NO_OPTIONS}
            value={getBooleanAsNumber(incident.drug_test_performed)}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentFields(
                  { drug_test_performed: null },
                  `Removed "Was a post incident drug & alcohol test performed" from Incident Detail`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentFields(
                  { drug_test_performed: option.value === 1 },
                  `Updated Incident Detail - "was drug test performed" to "${option.label}"`,
                );
            }}
            loading={updateIncidentLoading}
          />

          <IncidentSelectField
            title={"Lighting Conditions"}
            options={lightingConditions}
            value={incident.lighting_conditions ?? undefined}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentFields(
                  { lighting_conditions: null },
                  `Removed "Lighting Conditions" from Incident Detail`,
                );
              } else if (typeof option.value === "string")
                await updateIncidentFields(
                  {
                    lighting_conditions: option.value,
                  },
                  `Updated Incident Detail -  "lighting conditions" to "${option.label}"`,
                );
            }}
            loading={updateIncidentLoading}
          />
          <IncidentSelectField
            title={
              "Was there any equipment that contributed to the cause of the incident?"
            }
            options={YES_NO_OPTIONS}
            value={getBooleanAsNumber(incident.equipment_contributed)}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentFields(
                  { equipment_contributed: null },
                  `Removed "Was there any equipment that contributed to the cause of the incident?" from Incident Detail`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentFields(
                  { equipment_contributed: option.value === 1 },
                  `Updated Incident Detail -  "was equipment contributed" to "${option.label}"`,
                );
            }}
            loading={updateIncidentLoading}
          />
          {incident.equipment_contributed && (
            <IncidentTextField
              label="Equipment Contributed"
              text={incident.equipment_type.en}
              textId={incident.equipment_type.id}
              fieldTypeKey="incident_detail"
              field="equipment_type"
              incident={incident}
            />
          )}

          <IncidentSelectField
            title={
              "Any project impacts or delays due to incident (monetary, time, deliveries, etc.)?"
            }
            options={YES_NO_OPTIONS}
            value={getBooleanAsNumber(incident.project_impacted)}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentFields(
                  { project_impacted: null },
                  `Removed "Projects Impacted" from Incident Detail`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentFields(
                  { project_impacted: option.value === 1 },
                  `Updated Incident Detail - "Projects Impacted" to ${option.label}`,
                );
            }}
            loading={updateIncidentLoading}
          />

          <IncidentSelectField
            title={"Emergency Services Called"}
            options={YES_NO_OPTIONS}
            value={getBooleanAsNumber(incident.emergency_service_called)}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentFields(
                  { emergency_service_called: null },
                  `Removed "Emergency Services Called" from Incident Detail`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentFields(
                  { emergency_service_called: option.value === 1 },
                  `Updated Incident Detail - "Emergency Services Called" to ${option.label}`,
                );
            }}
            loading={updateIncidentLoading}
          />

          {incident.emergency_service_called && (
            <IncidentTextField
              required={true}
              label="Who was called (EMS, Police, etc)?"
              text={incident.emergency_service.en}
              textId={incident.emergency_service.id}
              fieldTypeKey="incident_detail"
              field="emergency_service"
              incident={incident}
            />
          )}

          <IncidentTextField
            label="If a road vehicle was involved, please provide Driver(s) Name, License # and Company Vehicle"
            text={incident.road_vehicle_info.en}
            textId={incident.road_vehicle_info.id}
            fieldTypeKey="incident_detail"
            field="road_vehicle_info"
            incident={incident}
          />

          <IncidentTextField
            label="What was immediately done to rectify the situation and prevent the
        incident from occurring again?"
            text={incident.corrective_action.en}
            textId={incident.corrective_action.id}
            fieldTypeKey="incident_detail"
            field="corrective_action"
            incident={incident}
          />
        </Card>

        <IncidentWeatherObservation incident={incident} />

        <Card>
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={incident.attached_files}
            groupId={incident.id}
            uploadDocument={uploadDocument}
            type="incident"
          />
        </Card>

        <IncidentBottomButtons
          onNextClick={() => {
            setSearchParams({ subview: "injured_user" });
          }}
          onSave={() => {
            updateIncidentFields(
              { detail_completed_at: dayjs().format() },
              `Incident Detail marked as Completed`,
            );
          }}
          saveDisabled={!saveEnabled}
        />
      </div>
    </IncidentSubViewLayout>
  );
};

export default withCustomSuspense(IncidentDetail);
