import { FC } from "react";
import {
  useUpdateTheftIncidentMutation,
  Theft_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/TextField";
import IncidentInputField from "../basic/InputField";
import IncidentSelectField, {
  getBooleanAsNumber,
  YES_NO_OPTIONS,
} from "../basic/SelectField";
import IncidentDatepicker from "../basic/Datepicker";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import IncidentSubViewLayout from "../IncidentSubViewLayout";
import IncidentMonetarySeverity from "../basic/IncidentMonetarySeverity";

const TheftIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [insertIncidentType, { loading: insertIncidentTypeLoading }] =
    useInsertIncidentType();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const [updateTheft, { loading: updateTheftLoading }] =
    useUpdateTheftIncidentMutation();
  const theft = incident.theft_incident;
  const documents = getIncidentDocuments(incident, "theft");
  if (!theft) {
    throw new Error("theft_incident is missing");
  }

  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "theft");
  };

  const [updateMonetarySeverity, { loading: updateMonetarySeverityLoading }] =
    useUpdateMonetarySeverity();
  const updateTheftIncident = async (
    _set: Omit<Theft_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      theft_incident: { ...theft, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateTheft({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "theft-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_theft_incident_by_pk: {
          ...theft,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "theft",
    });
  };

  const uploadDocuments = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "theft",
    });
  };

  const stolenItemsOwner = theft.stolen_items_owner;

  return (
    <IncidentSubViewLayout title="Theft/Vandalism">
      <div className="flex flex-col gap-1">
        <Card>
          <IncidentTextField
            required={true}
            label="List what was stolen or vandalized"
            text={theft.stolen_items.en}
            textId={theft.stolen_items.id}
            saveIncidentField={() => insertIncidentTypeLink()}
            fieldTypeKey={"theft"}
            field="stolen_items"
            incident={incident}
          />

          <IncidentMonetarySeverity
            incident={incident}
            incidentType="Theft/Vandalism"
          />
        </Card>
        <IncidentCommonUser
          title="Who did the stolen or vandalized material/equipment belong to?"
          user={stolenItemsOwner ?? undefined}
          onUpdateUser={async (id, name) => {
            updateTheftIncident(
              { stolen_items_owner_user_id: id },
              `Updated Theft/Vandalism -  "Who did the stolen or vandalized material/equipment belong to?" to ${name} `,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (stolenItemsOwner) {
              const updatedIncident = {
                ...incident,
                theft_incident: {
                  ...theft,
                  stolen_items_owner: { ...stolenItemsOwner, [key]: val },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated Theft/Vandalism - ${label} of the "Who did the stolen or vandalized material/equipment belong to?" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (stolenItemsOwner && stolenItemsOwner.general_person) {
              const updatedIncident = {
                ...incident,
                theft_incident: {
                  ...theft,
                  stolen_items_owner: {
                    ...stolenItemsOwner,
                    general_person: {
                      ...stolenItemsOwner.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated Theft/Vandalism - employer of the "Reported by user" to ${val}`;
              updateGeneralPerson(
                stolenItemsOwner.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          projectId={incident.project_id}
        />
        <Card>
          <IncidentInputField
            label="Where was it located or stored"
            defaultText={theft.stolen_items_location ?? undefined}
            onSave={(val) => {
              updateTheftIncident(
                { stolen_items_location: val },
                `Updated Theft/Vandalism -  "Where was it located or stored" to ${val} `,
              );
            }}
          />

          <IncidentDatepicker
            format={"YYYY-MM-DD h:mm A"}
            label={"When was it noticed stolen or vandalized"}
            onChange={async (val) =>
              await updateTheftIncident(
                { stolen_at: val },
                `Updated Theft/Vandalism -  "When was it noticed stolen or vandalized" to ${dayjs(
                  val,
                ).format("DD MMM, YYYY h:mm A")} `,
              )
            }
            showTime={true}
            value={theft.stolen_at ?? undefined}
            disabled={updateTheftLoading || insertIncidentTypeLoading}
          />

          <IncidentSelectField
            onChange={async (option) => {
              if (option === null) {
                await updateTheftIncident(
                  { was_reported_to_local_authorities: null },
                  `Updated Theft/Vandalism -  "Reported to local authorities" to "None" `,
                );
              } else if (typeof option.value === "number") {
                await updateTheftIncident(
                  { was_reported_to_local_authorities: option.value === 1 },
                  `Updated Theft/Vandalism -  "Reported to local authorities" to ${option.label} `,
                );
              }
            }}
            options={YES_NO_OPTIONS}
            title="Reported to local authorities"
            value={getBooleanAsNumber(theft.was_reported_to_local_authorities)}
            loading={
              insertIncidentTypeLoading ||
              updateTheftLoading ||
              updateMonetarySeverityLoading
            }
          />

          {theft.was_reported_to_local_authorities && (
            <IncidentTextField
              label="Details"
              text={theft.local_authorities_details.en}
              textId={theft.local_authorities_details.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey={"theft"}
              field="local_authorities_details"
              incident={incident}
            />
          )}
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "theft",
        ) !== -1 && (
          <Card>
            <IncidentDocViewAndUpload
              deleteDocument={deleteDocument}
              documents={documents}
              groupId={incident.id}
              uploadDocument={uploadDocuments}
              type="theft"
            />
          </Card>
        )}

        <IncidentBottomButtons
          saveDisabled={!theft.stolen_items.en}
          onNextClick={() => {
            const next = getNextPage(incident, "theft");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updateTheftIncident(
              {
                completed_at: dayjs().format(),
              },
              `Theft/Vandalism section marked as completed`,
            );
          }}
        />
      </div>
    </IncidentSubViewLayout>
  );
};

export default TheftIncident;
