/**
 * @generated SignedSource<<e4deb0b4038fd931714018843b981fb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateNewOrientationModalInsertMutation$variables = {
  object: orientation_insert_input;
};
export type CreateNewOrientationModalInsertMutation$data = {
  readonly insert_orientation_one: {
    readonly created_at: string;
    readonly name: string;
    readonly pk: string;
  } | null | undefined;
};
export type CreateNewOrientationModalInsertMutation = {
  response: CreateNewOrientationModalInsertMutation$data;
  variables: CreateNewOrientationModalInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNewOrientationModalInsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "orientation",
        "kind": "LinkedField",
        "name": "insert_orientation_one",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateNewOrientationModalInsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "orientation",
        "kind": "LinkedField",
        "name": "insert_orientation_one",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0ab37288f98c6a327218087f3cdb3de",
    "id": null,
    "metadata": {},
    "name": "CreateNewOrientationModalInsertMutation",
    "operationKind": "mutation",
    "text": "mutation CreateNewOrientationModalInsertMutation(\n  $object: orientation_insert_input!\n) {\n  insert_orientation_one(object: $object) {\n    pk: id\n    name\n    created_at\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "45cf23d2cb86b88c5773b51cf558684b";

export default node;
