/**
 * @generated SignedSource<<be25b15c37bd44eaa6821b7795497d89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { obs_notify_user_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$variables = {
  where: obs_notify_user_bool_exp;
};
export type useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$data = {
  readonly delete_obs_notify_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation = {
  response: useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$data;
  variables: useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "obs_notify_user_mutation_response",
    "kind": "LinkedField",
    "name": "delete_obs_notify_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "obs_notify_user",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e63ca4e54aaa506de3dcffd9756c9af",
    "id": null,
    "metadata": {},
    "name": "useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation",
    "operationKind": "mutation",
    "text": "mutation useGetSubadminObsRiskLevelColumns_DeleteObsNotify_Mutation(\n  $where: obs_notify_user_bool_exp!\n) {\n  delete_obs_notify_user(where: $where) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29b715faaa314fe610393944005f3de2";

export default node;
