/**
 * @generated SignedSource<<3bd453ed731aeb1bc97f3ed15d50493d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, permit_bool_exp, permit_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermitTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  includeProject: boolean;
  includeSub: boolean;
  order_by: ReadonlyArray<permit_order_by>;
  where: permit_bool_exp;
};
export type PermitTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PermitTable_permits">;
};
export type PermitTableRefetchableQuery = {
  response: PermitTableRefetchableQuery$data;
  variables: PermitTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeProject"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeSub"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v8/*: any*/)
],
v11 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "created_at": "desc"
  }
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PermitTableRefetchableQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "includeProject",
            "variableName": "includeProject"
          },
          {
            "kind": "Variable",
            "name": "includeSub",
            "variableName": "includeSub"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PermitTable_permits"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PermitTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "permitConnection",
        "kind": "LinkedField",
        "name": "permit_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permitEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current_status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "work_location",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "work_description",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "condition": "includeProject",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includeSub",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "limit",
                        "value": 1
                      },
                      (v11/*: any*/)
                    ],
                    "concreteType": "permit_history",
                    "kind": "LinkedField",
                    "name": "permit_histories",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": "permit_histories(limit:1,order_by:{\"created_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "confined_space_permit",
                    "kind": "LinkedField",
                    "name": "confined_space_permit",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          (v11/*: any*/)
                        ],
                        "concreteType": "confined_space_permit_reading",
                        "kind": "LinkedField",
                        "name": "confined_space_permit_readings",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "o2_percent",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "co_ppm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "h2s_ppm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lower_explosive_limit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "confined_space_permit_readings(order_by:{\"created_at\":\"desc\"})"
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ladder_permit",
                    "kind": "LinkedField",
                    "name": "ladder_permit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "is_access_ladder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ladder_identification",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_inspection_at",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "returned_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "returned_marked_by_uid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "returned_marked_by_user",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ceiling_access_permit",
                    "kind": "LinkedField",
                    "name": "ceiling_access_permit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closing_review_status",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "permit_project_worker_aggregate",
                    "kind": "LinkedField",
                    "name": "permit_project_workers_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "permit_project_worker_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "PermitTable_permit_connection",
        "kind": "LinkedHandle",
        "name": "permit_connection"
      }
    ]
  },
  "params": {
    "cacheID": "3251f63852e8813d1c23d4b052016e35",
    "id": null,
    "metadata": {},
    "name": "PermitTableRefetchableQuery",
    "operationKind": "query",
    "text": "query PermitTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $includeProject: Boolean!\n  $includeSub: Boolean!\n  $order_by: [permit_order_by!]!\n  $where: permit_bool_exp!\n) {\n  ...PermitTable_permits_1lAmFF\n}\n\nfragment PermitTable_permits_1lAmFF on query_root {\n  permit_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        type\n        created_at\n        updated_at\n        current_status\n        work_location {\n          id\n          en\n        }\n        work_description {\n          id\n          en\n        }\n        project @include(if: $includeProject) {\n          name\n          id\n        }\n        subcontractor @include(if: $includeSub) {\n          name\n          id\n        }\n        permit_histories(order_by: {created_at: desc}, limit: 1) {\n          created_at\n          id\n        }\n        confined_space_permit {\n          type\n          confined_space_permit_readings(order_by: {created_at: desc}) {\n            id\n            type\n            o2_percent\n            created_at\n            co_ppm\n            h2s_ppm\n            lower_explosive_limit\n          }\n          id\n        }\n        ladder_permit {\n          is_access_ladder\n          ladder_identification\n          last_inspection_at\n          id\n          returned_at\n          returned_marked_by_uid\n          returned_marked_by_user {\n            name\n            id\n          }\n        }\n        ceiling_access_permit {\n          closing_review_status\n          id\n        }\n        permit_project_workers_aggregate {\n          aggregate {\n            count\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9680858a86a7af22dbedfc0e78b99e64";

export default node;
