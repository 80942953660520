import { FC } from "react";
import {
  useUpdateUtilityIncidentMutation,
  Utility_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/TextField";
import IncidentSelectField from "../basic/SelectField";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import IncidentSubViewLayout from "../IncidentSubViewLayout";
import IncidentMonetarySeverity from "../basic/IncidentMonetarySeverity";
import { YES_NO_OPTIONS, getBooleanAsNumber } from "../basic/SelectField";

const UtilityIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();
  const [updateMonetarySeverity, { loading: updateMonetarySeverityLoading }] =
    useUpdateMonetarySeverity();
  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [updateUtilityIncident, { loading: updateUtilityIncidentLoading }] =
    useUpdateUtilityIncidentMutation();
  const [insertIncidentType, { loading: insertIncidentTypeLoading }] =
    useInsertIncidentType();
  const utility = incident.utility_incident;
  const documents = getIncidentDocuments(incident, "utility");
  if (!utility) {
    throw new Error("utlity_incident is missing");
  }

  const serviceTypeOptions = [
    { label: "Communications", value: "communication" },
    {
      label: "Electrical - High Voltage",
      value: "electrical_high_voltage",
    },
    {
      label: "Electrical - Low Voltage",
      value: "electrical_low_voltage",
    },
    { label: "Fiber Optics", value: "fiber_optics" },
    {
      label: "Gas High Pressure",
      value: "gas_high_pressure",
    },
    {
      label: "Gas Low Pressure",
      value: "gas_low_pressure",
    },
    { label: "Sewar", value: "sewar" },
    { label: "Storm", value: "storm" },
    { label: "Water", value: "water" },
    { label: "Other", value: "other" },
  ];

  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "utility");
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "utility",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "utility",
    });
  };

  const updateUtility = async (
    _set: Omit<Utility_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      utility_incident: { ...utility, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateUtilityIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "utility-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_utility_incident_by_pk: {
          ...utility,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <IncidentSubViewLayout title="Utility/Service Strike">
      <div className="flex flex-col gap-1">
        <Card>
          <IncidentSelectField
            onChange={async (option) => {
              if (option === null) {
                await updateUtility(
                  { service_hit: null },
                  `Updated Utility/Service Strike - "What type of service was hit" to "None" `,
                );
              } else if (typeof option.value === "string") {
                await updateUtility(
                  { service_hit: option.value },
                  `Updated Utility/Service Strike - "What type of service was hit" to "${option.label}" `,
                );
              }
            }}
            options={serviceTypeOptions}
            title="What type of service was hit"
            required={true}
            value={utility.service_hit ?? undefined}
            loading={
              insertIncidentTypeLoading ||
              updateUtilityIncidentLoading ||
              updateMonetarySeverityLoading
            }
          />

          <IncidentMonetarySeverity
            incident={incident}
            incidentType="Utility/Service Strike"
          />

          <IncidentSelectField
            onChange={async (option) => {
              if (option === null) {
                await updateUtility(
                  {
                    digsafe_requested: null,
                  },
                  `Updated Utility/Service Strike - "Was DigSafe or similar local organization requested" to "None" `,
                );
              } else if (typeof option.value === "number") {
                await updateUtility(
                  { digsafe_requested: option.value === 1 },
                  `Updated Utility/Service Strike - "Was DigSafe or similar local organization requested" to "${option.label}" `,
                );
              }
            }}
            options={YES_NO_OPTIONS}
            title="Was DigSafe or similar local organization requested"
            value={getBooleanAsNumber(utility.digsafe_requested)}
            loading={
              insertIncidentTypeLoading ||
              updateUtilityIncidentLoading ||
              updateMonetarySeverityLoading
            }
          />

          <IncidentSelectField
            onChange={async (option) => {
              if (option === null) {
                await updateUtility(
                  { markings_verified: null },
                  `Updated Utility/Service Strike - "Were markings checked/potholed/verified" to "None" `,
                );
              } else if (typeof option.value === "number") {
                await updateUtility(
                  { markings_verified: option.value === 1 },
                  `Updated Utility/Service Strike - "Were markings checked/potholed/verified" to "${option.label}" `,
                );
              }
            }}
            options={YES_NO_OPTIONS}
            title="Were markings checked/potholed/verified"
            value={getBooleanAsNumber(utility.markings_verified)}
            loading={
              insertIncidentTypeLoading ||
              updateUtilityIncidentLoading ||
              updateMonetarySeverityLoading
            }
          />

          <IncidentSelectField
            onChange={async (option) => {
              if (option === null) {
                await updateUtility(
                  { utility_marked: null },
                  `Updated Utility/Service Strike - "Was utility marked out" to "None" `,
                );
              } else if (typeof option.value === "number") {
                await updateUtility(
                  { utility_marked: option.value === 1 },
                  `Updated Utility/Service Strike - "Was utility marked out" to "${option.label}" `,
                );
              }
            }}
            options={YES_NO_OPTIONS}
            title="Was utility marked out"
            value={getBooleanAsNumber(utility.utility_marked)}
            loading={
              insertIncidentTypeLoading ||
              updateUtilityIncidentLoading ||
              updateMonetarySeverityLoading
            }
          />

          {utility.utility_marked === false && (
            <IncidentTextField
              label="Why was utility not marked out"
              text={utility.utility_not_marked_detail.en}
              textId={utility.utility_not_marked_detail.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="utility"
              incident={incident}
              field="utility_not_marked_detail"
            />
          )}

          {incident.incident_types.findIndex(
            (indexType) => indexType.type_value === "utility",
          ) !== -1 && (
            <IncidentDocViewAndUpload
              deleteDocument={deleteDocument}
              documents={documents}
              groupId={incident.id}
              uploadDocument={uploadDocument}
              type="utility"
            />
          )}
        </Card>

        <IncidentBottomButtons
          saveDisabled={!utility.service_hit}
          onNextClick={() => {
            const next = getNextPage(incident, "utility");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updateUtility(
              { completed_at: dayjs().format() },
              `Utility/Service Strike section marked as completed`,
            );
          }}
        />
      </div>
    </IncidentSubViewLayout>
  );
};

export default UtilityIncident;
