import NestedRoute from "src/common/types/manual/NestedRoute";
import { Navigate, Outlet } from "react-router-dom";
import GCCorporateOfficeOrientations from "./orientation/GCCorporateOfficeOrientations";
import searchParamsProvider from "../../../../../../common/functions/searchParamsProvider";
import GCProjectOrientationDetail from "../../../../../../domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import GCCorporateOfficeWorkers from "./worker/GCCorporateOfficeWorkers";
import GCCorporateObservations from "./observation/GCCorporateOfficeObservations";
import GCCorporateSettingsPermits from "./permits/GCCorporateOfficePermitsSettings";
import GCCorporateOfficeEmployees from "./users/employees/GCCorporateOfficeEmployees";
import GCCorporateProjects from "./projects/GCCorporateOfficeProjects";
import GCCorporateOfficeAdmins from "./users/admins/GCCorporateOfficeAdmins";
import GCCompanyEmployees from "./employees/GCCompanyEmployees";
import GCCompanyHierarchy from "./hierarchy/GCCompanyHierarchy";
import GCCompanySettings from "./settings/GCCompanySettings";
import gcCompanySettingsRoutedMenu from "./settings/gcCompanySettingsRoutedMenu";
import GCCorporateIncidentTabs from "../../../../../../domain-features/incident-management/entryRoutes/gc/GCCorporateIncidentTabs";
import GcIncidentManagement from "../../../../../../domain-features/incident-management/entryRoutes/gc/GcIncidentManagement";
import GCCorporateIncidentsOverview from "./incident/GCCorporateIncidentsOverview";
import GCCorporateIncidentsTable from "./incident/GCCorporateIncidentsTable";
import GCCorporateOfficeChildUnits from "./child-units/GCCorporateOfficeChildUnits";
import getRoutesByRoutedMenu from "../../../../../../utility-features/navigation/routed-menu/getRoutesByRoutedMenu";
import GCCorporateWorkersTabs from "./worker/GCCorporateWorkersTabs";
import GCEmployeeVerification from "../../employeeVerification/GCEmployeeVerification";

const gcCorporateOfficeRoute: NestedRoute = {
  element: <Outlet />,
  handle: {
    breadcrumb: "Corporate Office",
  },
  children: [
    // TODO Add Dashboard page
    // {
    //   path: "dashboard",
    //   element: null,
    // },
    {
      path: "projects",
      element: <GCCorporateProjects />,
      handle: {
        breadcrumb: "Projects",
      },
    },
    {
      path: "workers",
      element: <GCCorporateWorkersTabs />,
      // element: <GCCorporateOfficeWorkers />,
      handle: { breadcrumb: "Workers" },
      children: [
        { path: "", element: <Navigate to="workers" /> },
        { path: "workers", element: <GCCorporateOfficeWorkers /> },
        { path: "worker-verification", element: <GCEmployeeVerification /> },
      ],
    },
    {
      path: "incidents",
      element: <GCCorporateIncidentTabs />,
      children: [
        { path: "", element: <Navigate to="table" /> },
        { path: "charts", element: <GCCorporateIncidentsOverview /> },
        {
          path: "table",
          element: <GCCorporateIncidentsTable />,
          children: [
            { path: ":incidentId", element: <GcIncidentManagement /> },
          ],
        },
      ],
    },
    {
      path: "observations",
      element: <GCCorporateObservations />,
      handle: {
        breadcrumb: "Observations",
      },
    },
    {
      path: "permits",
      element: <GCCorporateSettingsPermits />,
      handle: {
        breadcrumb: "Permits",
      },
    },
    {
      path: "orientations",

      handle: {
        breadcrumb: "Orientations",
      },
      children: [
        {
          path: "",
          element: <GCCorporateOfficeOrientations />,
        },
        {
          path: ":orientationId",
          element: searchParamsProvider(GCProjectOrientationDetail),
          handle: {
            breadcrumb: "Orientation",
          },
        },
      ],
    },
    {
      path: "employees",
      element: <GCCorporateOfficeEmployees />,
      handle: {
        breadcrumb: "Employees",
      },
    },
    {
      path: "admins",
      element: <GCCorporateOfficeAdmins />,
      handle: {
        breadcrumb: "Admins",
      },
    },
    {
      path: "employees",
      element: <GCCompanyEmployees />,
      handle: {
        breadcrumb: "Employees",
      },
    },
    {
      path: "hierarchy",
      element: <GCCompanyHierarchy />,
      handle: {
        breadcrumb: "Hierarchy",
      },
    },
    {
      path: "settings",
      element: <GCCompanySettings />,
      handle: {
        breadcrumb: "Settings",
      },
      children: getRoutesByRoutedMenu(gcCompanySettingsRoutedMenu),
    },
    {
      path: "child-units",
      element: <GCCorporateOfficeChildUnits />,
      handle: {
        breadcrumb: "Child Units",
      },
      children: [
        {
          path: ":subview",
        },
      ],
    },
    {
      path: "",
      element: <Navigate to="projects" replace />,
    },
  ],
};

export default gcCorporateOfficeRoute;
