import { FC, useState, useMemo } from "react";
import { Card, Input, Select, Form, Tag } from "antd";
import {
  GetIncidentByPkQuery,
  useGetInfoForInjuryDetailQuery,
  useUpdateInjuryDetailMutation,
  Injury_Detail_Set_Input,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentTextField from "../basic/TextField";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import IncidentDatepicker from "../basic/Datepicker";
import IncidentSelectField from "../basic/SelectField";
import IncidentInputField from "../basic/InputField";
import dayjs from "dayjs";
import useUpdateInjuryDetailPpeTypes from "../../utils/useUpdateInjuryDetailPpeTypes";
import useUpdateInjuryDetailInjuryTypes from "../../utils/useUpdateInjuryDetailInjuryTypes";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import InjuryDetailBodyParts from "src/domain-features/incident-management/components/InjuryDetailBodyParts";
import useUploadIncidentDocuments from "src/domain-features/incident-management/utils/useUploadIncidentDocuments";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import { YES_NO_OPTIONS, getBooleanAsNumber } from "../basic/SelectField";

interface InjuryDetailProps {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  injuredUserId: string;
}

interface InjuryTypeDetail {
  id: string;
  injury_type: string;
}

const InjuryDetail: FC<InjuryDetailProps> = ({ incident, injuredUserId }) => {
  const { data: dropdownData } = useGetInfoForInjuryDetailQuery();
  const [updateInjuryDetails, { loading: updateInjuryDetailsLoading }] =
    useUpdateInjuryDetailMutation();
  const [updateInjuryDetailPpeTypes] = useUpdateInjuryDetailPpeTypes();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();
  const [updateInjuryDetailInjuryTypes] = useUpdateInjuryDetailInjuryTypes();

  const authUser = useAuthUser();
  const injuryDetail = useMemo(() => {
    const injuryDetail = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    )?.injury_detail;

    return injuryDetail;
  }, [incident.injured_users, injuredUserId]);

  if (!injuryDetail) {
    throw new Error("injury_detail is missing");
  }

  const [updatingInjuryDetailInjuryTypes, setUpdatingInjuryDetailInjuryTypes] =
    useState(false);
  const [loadingOptions, setLoadingOptions] = useState<Set<string>>(new Set());

  const updateIncidentDetail = async (
    _set: Omit<Injury_Detail_Set_Input, "id">,
    comment: string,
  ) => {
    const updatedInjuredUsers = incident.injured_users.map((injuredUser) => {
      if (injuredUser.id === injuredUserId && injuredUser.injury_detail) {
        return {
          ...injuredUser,
          injury_detail: {
            ...injuredUser.injury_detail,
            ..._set,
          },
        };
      } else return injuredUser;
    });
    const updatedIncident = { ...incident, injured_users: updatedInjuredUsers };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateInjuryDetails({
      variables: {
        id: injuryDetail.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "injury-detail-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_injury_detail_by_pk: {
          ...injuryDetail,
          id: injuryDetail.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const {
    ppeOptions,
    bodyParts,
    injuryCauseOptions,
    injuryTypeOptions,
    injurySeverityOptions,
    ppeMap,
  } = useMemo(() => {
    const bodyParts: Array<{
      label: string;
      value: string;
    }> = [];

    const ppeMap: { [key: string]: string } = {};

    dropdownData?.injury_body_part_affected.forEach((bodyPart) => {
      bodyParts.push({
        label: bodyPart.translation.en,
        value: bodyPart.value,
      });
    });

    const ppeOptions = dropdownData?.ppe_category.map((category) => ({
      id: category.id,
      label: category.name.en,
      value: category.id,
      ppe: category.ppe_types.map((ppe) => {
        ppeMap[ppe.id] = ppe.name.en;
        return {
          id: ppe.id,
          label: ppe.name.en,
          value: ppe.id,
        };
      }),
    }));

    const injuryCauseOptions = dropdownData?.injury_cause.map(
      (injuryCause) => ({
        label: injuryCause.translation.en,
        value: injuryCause.value,
      }),
    );

    const injuryTypeOptions = dropdownData?.injury_type.map((injuryType) => ({
      label: injuryType.translation.en,
      value: injuryType.value,
    }));

    const injurySeverityOptions = dropdownData?.incident_severity.map(
      (incidentSeverity) => ({
        label: incidentSeverity.name.en,
        value: incidentSeverity.id,
      }),
    );

    return {
      ppeOptions,
      bodyParts,
      injuryCauseOptions,
      injuryTypeOptions,
      injurySeverityOptions,
      ppeMap,
    };
  }, [dropdownData]);

  const helperPpe = injuryDetail.injury_detail_ppe_types
    .filter((ppe) => ppe.type === "helper")
    .map((helper) => helper.ppe_type_id);

  const wornPpe = injuryDetail.injury_detail_ppe_types
    .filter((ppe) => ppe.type === "worn")
    .map((worn) => worn.ppe_type_id);

  const injuryDetailInjuryTypes = injuryDetail.injury_detail_injury_types.map(
    (injuryType) => injuryType.injury_type,
  );

  const getLabelFromMap = (
    ids: Array<string>,
    labelMap: { [key: string]: string },
  ) => {
    const labels = ids.map((id) => labelMap[id]).join(", ");
    return labels;
  };

  const [helperPpeTypes, setHelperPpeTypes] =
    useState<Array<string>>(helperPpe);
  const [wornPpeTypes, setWornPpeTypes] = useState<Array<string>>(wornPpe);
  const [injuryTypes, setInjuryTypes] = useState<Array<string>>(
    injuryDetailInjuryTypes,
  );

  const updateInjuryPpeTypes = async (
    type: string,
    toBeInsertedPpeTypeIds: Array<string>,
    toBeDeletedPpeTypeIds: Array<string>,
    comment: string,
  ) => {
    if (!injuredUserId) return;
    await updateInjuryDetailPpeTypes(
      type,
      toBeInsertedPpeTypeIds,
      toBeDeletedPpeTypeIds,
      incident,
      injuredUserId,
      comment,
    );
  };

  const updateInjuryTypes = async (
    toBeInserted: string[],
    toBeDeletedIds: string[],
    comment: string,
  ) => {
    await updateInjuryDetailInjuryTypes(
      toBeInserted,
      toBeDeletedIds,
      incident,
      injuredUserId,
      comment,
    );
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "injury_detail",
      incidentUserId: injuredUserId,
      incident,
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "injury_detail",
      incident: incident,
      objects: objects,
      incidentUserId: injuredUserId,
    });
  };

  const userName =
    incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    )?.user.name ?? "injured user";

  const lostDays = (
    injuryDetail.date_back_to_work
      ? dayjs(injuryDetail.date_back_to_work)
      : dayjs()
  ).diff(dayjs(injuryDetail.date_out_of_work), "day");

  const restrictedDutyDays = (
    injuryDetail.date_end_restricted_duty
      ? dayjs(injuryDetail.date_end_restricted_duty)
      : dayjs()
  ).diff(dayjs(injuryDetail.date_start_restricted_duty), "day");

  const handleSelect = async (selectedValue: string) => {
    setLoadingOptions((prev) => new Set(prev).add(selectedValue));
    setUpdatingInjuryDetailInjuryTypes(true);
    const comment = `Updated "Select injury types" for the injured user ${userName} - Added ${selectedValue}`;
    await updateInjuryTypes([selectedValue], [], comment);
    setLoadingOptions((prev) => {
      const newSet = new Set(prev);
      newSet.delete(selectedValue);
      return newSet;
    });
    setUpdatingInjuryDetailInjuryTypes(false);
  };

  const handleDeselect = async (deselectedValue: string) => {
    setLoadingOptions((prev) => new Set(prev).add(deselectedValue));
    setUpdatingInjuryDetailInjuryTypes(true);
    const initialInjuryTypes = injuryDetail.injury_detail_injury_types.map(
      (injuryType): InjuryTypeDetail => ({
        id: injuryType.id,
        injury_type: injuryType.injury_type,
      }),
    );
    const toBeDeletedId = initialInjuryTypes.find(
      (it) => it.injury_type === deselectedValue,
    )?.id;

    if (toBeDeletedId) {
      const comment = `Updated "Select injury types" for the injured user ${userName} - Removed ${deselectedValue}`;

      await updateInjuryTypes([], [toBeDeletedId], comment);
    }

    setLoadingOptions((prev) => {
      const newSet = new Set(prev);
      newSet.delete(deselectedValue);
      return newSet;
    });
    setUpdatingInjuryDetailInjuryTypes(false);
  };

  return (
    <div className="top-5 flex flex-col">
      <div className="flex flex-col gap-1">
        <Card title="Injury/Illness Details">
          <IncidentSelectField
            title="Injury Classification"
            options={injurySeverityOptions ?? []}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { injury_severity_id: null },
                  `Removed Injury Classification for the injured user ${userName}`,
                );
              } else if (typeof option.value === "string") {
                await updateIncidentDetail(
                  { injury_severity_id: option.value },
                  `Updated Injury Classification for the injured user ${userName} to ${option.label}`,
                );
              }
            }}
            value={injuryDetail.injury_severity_id ?? undefined}
            loading={updateInjuryDetailsLoading}
          />

          <IncidentTextField
            label="Detailed Description of Injury"
            text={injuryDetail.description.en}
            textId={injuryDetail.description.id}
            fieldTypeKey="injury_detail"
            field="description"
            incidentUserId={injuredUserId}
            incident={incident}
          />

          <InjuryDetailBodyParts
            incident={incident}
            injuredUserId={injuredUserId}
            options={bodyParts}
          />

          <IncidentSelectField
            title="Cause of injury"
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { injury_cause: null },
                  `Removed cause of injury for the injured user ${userName}`,
                );
              } else if (typeof option.value === "string") {
                await updateIncidentDetail(
                  { injury_cause: option.value },
                  `Updated cause of injury for the injured user ${userName} to "${option.label}"`,
                );
              }
            }}
            options={injuryCauseOptions ?? []}
            value={injuryDetail.injury_cause ?? undefined}
            loading={updateInjuryDetailsLoading}
          />

          <Form.Item label="Select injury types" layout="vertical">
            <Select
              mode="multiple"
              loading={updatingInjuryDetailInjuryTypes}
              value={injuryTypes}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={
                injuryTypeOptions?.map((injuryType) => ({
                  value: injuryType.value,
                  label: injuryType.label,
                  disabled: loadingOptions.has(injuryType.value),
                })) ?? []
              }
              onChange={(selectedValues) => {
                setInjuryTypes(selectedValues);
              }}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
            />
          </Form.Item>

          <Form.Item
            label="Select PPE worn at the time of the incident"
            layout="vertical"
          >
            <Select
              mode="multiple"
              loading={updateInjuryDetailsLoading}
              value={wornPpeTypes}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={
                ppeOptions?.flatMap((opt) =>
                  opt.ppe.map((ppe) => ({
                    value: ppe.id,
                    label: ppe.label,
                    disabled: loadingOptions.has(ppe.id),
                  })),
                ) ?? []
              }
              onChange={(selectedValues) => {
                setWornPpeTypes(selectedValues);
              }}
              onSelect={async (value) => {
                setLoadingOptions((prev) => new Set(prev).add(value));
                const comment = `Updated "Select PPE worn at the time of the incident" for the injured user ${userName} - Added ${ppeMap[value]}`;
                await updateInjuryPpeTypes("worn", [value], [], comment);
                setLoadingOptions((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(value);
                  return newSet;
                });
              }}
              onDeselect={async (value) => {
                setLoadingOptions((prev) => new Set(prev).add(value));
                const comment = `Updated "Select PPE worn at the time of the incident" for the injured user ${userName} - Removed ${ppeMap[value]}`;
                await updateInjuryPpeTypes("worn", [], [value], comment);
                setLoadingOptions((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(value);
                  return newSet;
                });
              }}
              className="w-full"
              placeholder="Select PPE worn"
              maxTagCount="responsive"
            />
          </Form.Item>

          <Form.Item
            label="Select any PPE that could have helped avoid this injury"
            layout="vertical"
          >
            <Select
              mode="multiple"
              loading={updateInjuryDetailsLoading}
              value={helperPpeTypes}
              options={
                ppeOptions?.flatMap((opt) =>
                  opt.ppe.map((ppe) => ({
                    value: ppe.id,
                    label: ppe.label,
                    disabled: loadingOptions.has(ppe.id),
                  })),
                ) ?? []
              }
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(selectedValues) => {
                setHelperPpeTypes(selectedValues);
              }}
              onSelect={async (value) => {
                setLoadingOptions((prev) => new Set(prev).add(value));
                const comment = `Updated "Select any PPE that could have helped avoid this injury" for the injured user ${userName} - Added ${ppeMap[value]}`;
                await updateInjuryPpeTypes("helper", [value], [], comment);
                setLoadingOptions((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(value);
                  return newSet;
                });
              }}
              onDeselect={async (value) => {
                setLoadingOptions((prev) => new Set(prev).add(value));
                const comment = `Updated "Select any PPE that could have helped avoid this injury" for the injured user ${userName} - Removed ${ppeMap[value]}`;
                await updateInjuryPpeTypes("helper", [], [value], comment);
                setLoadingOptions((prev) => {
                  const newSet = new Set(prev);
                  newSet.delete(value);
                  return newSet;
                });
              }}
              className="w-full"
              placeholder="Select PPE that could have helped"
              maxTagCount="responsive"
            />
          </Form.Item>

          <IncidentSelectField
            title="Did the Injury/Illness occur at work/project location?"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { injury_occurred_at_work_location: null },
                  `Removed "Did the Injury/Illness occur at work/project location?" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { injury_occurred_at_work_location: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Did the Injury/Illness occur at work/project location?"  to "${option.label}"`,
                );
            }}
            value={getBooleanAsNumber(
              injuryDetail.injury_occurred_at_work_location,
            )}
            loading={updateInjuryDetailsLoading}
          />

          <IncidentSelectField
            title="Is the Injury/Illness work related?"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { injury_work_related: null },
                  `Removed "Is the Injury/Illness work related?" from Injury Detail of ${userName}`,
                );
              } else if (typeof option?.value === "number")
                await updateIncidentDetail(
                  { injury_work_related: option.value === 1 },
                  `Updated Injury Detail of ${userName} -  "Is the Injury/Illness work related?" to "${option.label}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.injury_work_related)}
            loading={updateInjuryDetailsLoading}
          />

          <IncidentSelectField
            title="Treatment given"
            options={[
              {
                value: "no_medical_treatment",
                label: "No Medical Treatment/Refused",
              },
              {
                value: "minor_first_aid",
                label: "First Aid/On site",
              },
              { value: "physician_clinic", label: "Physician/Clinic" },
              {
                value: "emergency_room",
                label: "Emergency Room (treated & released)",
              },
              {
                value: "hospitalized",
                label: "Hospitalized Overnight as an in-patient",
              },
            ]}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { initial_medical_treatment: null },
                  `Removed "Treatment given" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "string")
                await updateIncidentDetail(
                  { initial_medical_treatment: option.value },
                  `Updated Injury Detail of ${userName} -  "Treatment given" to "${option.label}" `,
                );
            }}
            value={injuryDetail.initial_medical_treatment ?? undefined}
            loading={updateInjuryDetailsLoading}
          />

          <IncidentSelectField
            title="Onsite first aid given"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { onsite_treatment_given: null },
                  `Removed "Onsite first aid given" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { onsite_treatment_given: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Onsite first aid given" to "${option.label}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.onsite_treatment_given)}
            loading={updateInjuryDetailsLoading}
          />
          {injuryDetail.onsite_treatment_given && (
            <IncidentTextField
              label="Onsite Treatment Details (by whom and what was given)"
              text={injuryDetail.onsite_treatment_detail.en}
              textId={injuryDetail.onsite_treatment_detail.id}
              fieldTypeKey="injury_detail"
              field="onsite_treatment_detail"
              incidentUserId={injuredUserId}
              incident={incident}
            />
          )}

          <IncidentSelectField
            title="Offsite medical treatment"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { offsite_treatment_required: null },
                  `Removed "Offsite medical treatment" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { offsite_treatment_required: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Offsite medical treatment" to "${option.label}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.offsite_treatment_required)}
            loading={updateInjuryDetailsLoading}
          />

          {injuryDetail.offsite_treatment_required && (
            <IncidentTextField
              label="What treatment was provided"
              text={injuryDetail.offsite_treatment_detail.en}
              textId={injuryDetail.offsite_treatment_detail.id}
              fieldTypeKey="injury_detail"
              field="offsite_treatment_detail"
              incidentUserId={injuredUserId}
              incident={incident}
            />
          )}

          {injuryDetail.offsite_treatment_required && (
            <>
              <IncidentInputField
                label="Treatment facility name and address"
                defaultText={
                  injuryDetail.treatment_facility_address ?? undefined
                }
                onSave={(val) => {
                  updateIncidentDetail(
                    { treatment_facility_address: val },
                    `Updated Injury Detail of ${userName} - "Treatment facility name and address" to "${val}" `,
                  );
                }}
              />

              <IncidentSelectField
                title="Was person treated in an emergency room"
                options={YES_NO_OPTIONS}
                onChange={async (option) => {
                  if (option === null) {
                    await updateIncidentDetail(
                      { treatment_in_emergency_room: null },
                      `Removed "Was person treated in an emergency room" from Injury Detail of ${userName}`,
                    );
                  } else if (typeof option.value === "number")
                    await updateIncidentDetail(
                      { treatment_in_emergency_room: option.value === 1 },
                      `Updated Injury Detail of ${userName} - "Was person treated in an emergency room" to "${option.label}" `,
                    );
                }}
                value={getBooleanAsNumber(
                  injuryDetail.treatment_in_emergency_room,
                )}
                loading={updateInjuryDetailsLoading}
              />

              <IncidentInputField
                label="Physician or health care professional"
                defaultText={
                  injuryDetail.offsite_treatment_physician ?? undefined
                }
                onSave={(val) => {
                  updateIncidentDetail(
                    { offsite_treatment_physician: val },
                    `Updated Injury Detail of ${userName} - "Physician or health care professional" to "${val}" `,
                  );
                }}
              />
            </>
          )}

          <IncidentSelectField
            title="Was person hospitalized overnight as an in-patient"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { person_taken_to_hospital: null },
                  `Removed "Was person hospitalized overnight as an in-patient" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { person_taken_to_hospital: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Was person hospitalized overnight as an in-patient" to "${option.value}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.person_taken_to_hospital)}
            loading={updateInjuryDetailsLoading}
          />

          {injuryDetail.person_taken_to_hospital && (
            <>
              <IncidentTextField
                label="What treatment was provided"
                text={injuryDetail.hospital_treatment_detail.en}
                textId={injuryDetail.hospital_treatment_detail.id}
                fieldTypeKey="injury_detail"
                field="hospital_treatment_detail"
                incidentUserId={injuredUserId}
                incident={incident}
              />

              <IncidentInputField
                label="How many days stay"
                defaultText={injuryDetail.days_in_hospital ?? undefined}
                onSave={(val) => {
                  updateIncidentDetail(
                    { days_in_hospital: val },
                    `Updated Injury Detail of ${userName} - "How many days stay" to "${val}" `,
                  );
                }}
              />

              <IncidentInputField
                label="Hospital Address"
                defaultText={
                  injuryDetail.treatment_facility_address ?? undefined
                }
                onSave={(val) => {
                  updateIncidentDetail(
                    { treatment_facility_address: val },
                    `Updated Injury Detail of ${userName} - "Hospital Address" to "${val}" `,
                  );
                }}
              />

              <IncidentInputField
                label="Physician or health care professional"
                defaultText={injuryDetail.hospital_physician ?? undefined}
                onSave={(val) => {
                  updateIncidentDetail(
                    { hospital_physician: val },
                    `Updated Injury Detail of ${userName} - "Physician or health care professional" to "${val}" `,
                  );
                }}
              />
            </>
          )}

          <IncidentDatepicker
            label="Date treatment received"
            onChange={async (val) => {
              if (typeof val === "string")
                await updateIncidentDetail(
                  { treatment_date: val },
                  `Updated Injury Detail of ${userName} - "Date treatment received" to "${dayjs(
                    val,
                  ).format("DD MMM, YYYY")}" `,
                );
            }}
            showTime={false}
            value={injuryDetail.treatment_date ?? undefined}
            disabled={updateInjuryDetailsLoading}
          />

          <div className="mb-2">Lost Time/Days Away</div>

          <IncidentSelectField
            title="Was person unable to return to work due to this injury/illness?"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { unable_to_return_to_work: null },
                  `Removed "Was person unable to return to work due to this injury/illness?" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { unable_to_return_to_work: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Was person unable to return to work due to this injury/illness?" to "${option.value}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.unable_to_return_to_work)}
            loading={updateInjuryDetailsLoading}
          />

          {injuryDetail.unable_to_return_to_work && (
            <>
              <IncidentDatepicker
                label="Date out"
                onChange={async (date) => {
                  await updateIncidentDetail(
                    { date_out_of_work: date },
                    `Updated Injury Detail of ${userName} - "Date out" to "${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}" `,
                  );
                }}
                value={injuryDetail.date_out_of_work ?? undefined}
                disabled={updateInjuryDetailsLoading}
              />

              <IncidentDatepicker
                label="Date back"
                onChange={async (date) => {
                  await updateIncidentDetail(
                    { date_back_to_work: date },
                    `Updated Injury Detail of ${userName} - "Date back" to "${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}" `,
                  );
                }}
                value={injuryDetail.date_back_to_work ?? undefined}
                disabled={updateInjuryDetailsLoading}
              />

              <div className="flex gap-1 mt-1 items-center">
                <div className="w-10">Lost Time (# of days)</div>
                <Input className="w-full" value={lostDays} />
              </div>
            </>
          )}

          <div className="mb-2">Restricted Duty/Job Transfer</div>

          <IncidentSelectField
            title="Was person unable to return to FULL duty"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { unable_to_return_to_full_duty: null },
                  `Removed "Was person unable to return to FULL duty" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { unable_to_return_to_full_duty: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Was person unable to return to FULL duty" to "${option.value}" `,
                );
            }}
            value={getBooleanAsNumber(
              injuryDetail.unable_to_return_to_full_duty,
            )}
            loading={updateInjuryDetailsLoading}
          />

          {injuryDetail.unable_to_return_to_full_duty && (
            <>
              <IncidentDatepicker
                label="Date start"
                onChange={async (date) => {
                  await updateIncidentDetail(
                    { date_start_restricted_duty: date },
                    `Updated Injury Detail of ${userName} - "Date start" to " ${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}" `,
                  );
                }}
                value={injuryDetail.date_start_restricted_duty ?? undefined}
                disabled={updateInjuryDetailsLoading}
              />

              <IncidentDatepicker
                label="Date end"
                onChange={async (date) => {
                  await updateIncidentDetail(
                    { date_end_restricted_duty: date },
                    `Updated Injury Detail of ${userName} - "Date end" to "${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}" `,
                  );
                }}
                value={injuryDetail.date_end_restricted_duty ?? undefined}
                disabled={updateInjuryDetailsLoading}
              />

              <div className="flex gap-1 mt-1 items-center">
                <div className="w-10">Restricted Duty (# of days)</div>
                <Input className="w-full" value={restrictedDutyDays} />
              </div>
            </>
          )}

          <IncidentSelectField
            title="In-patient hospitalization, amputation, or loss of an eye"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { amputation_or_eye_loss: null },
                  `Removed "In-patient hospitalization, amputation, or loss of an eye" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  {
                    amputation_or_eye_loss: option.value === 1,
                  },
                  `Updated Injury Detail of ${userName} - "In-patient hospitalization, amputation, or loss of an eye" to "${option.label}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.amputation_or_eye_loss)}
            loading={updateInjuryDetailsLoading}
          />

          <IncidentSelectField
            title="Did the injury result in a fatality"
            options={YES_NO_OPTIONS}
            onChange={async (option) => {
              if (option === null) {
                await updateIncidentDetail(
                  { injury_resulted_in_fatality: null },
                  `Removed "Did the injury result in a fatality" from Injury Detail of ${userName}`,
                );
              } else if (typeof option.value === "number")
                await updateIncidentDetail(
                  { injury_resulted_in_fatality: option.value === 1 },
                  `Updated Injury Detail of ${userName} - "Did the injury result in a fatality" to "${option.label}" `,
                );
            }}
            value={getBooleanAsNumber(injuryDetail.injury_resulted_in_fatality)}
            loading={updateInjuryDetailsLoading}
          />

          {injuryDetail.injury_resulted_in_fatality && (
            <IncidentDatepicker
              label="Date of death"
              onChange={async (date) => {
                await updateIncidentDetail(
                  { date_of_death: date },
                  `Updated Injury Detail of ${userName} - "Date of death" to "${dayjs(
                    date,
                  ).format("DD MMM, YYYY")}" `,
                );
              }}
              value={injuryDetail.date_of_death ?? undefined}
              disabled={updateInjuryDetailsLoading}
            />
          )}

          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={injuryDetail.attached_files}
            groupId={injuryDetail.id}
            uploadDocument={uploadDocument}
            type="incident"
          />
        </Card>
      </div>
    </div>
  );
};

export default InjuryDetail;
