/**
 * @generated SignedSource<<dd4984b2ca2eb2ff55b8eaabe782a379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_data_sheet_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyAndAddSDS_Insert_SDS_Mutation$variables = {
  objects: ReadonlyArray<safety_data_sheet_insert_input>;
};
export type VerifyAndAddSDS_Insert_SDS_Mutation$data = {
  readonly insert_safety_data_sheet: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type VerifyAndAddSDS_Insert_SDS_Mutation = {
  response: VerifyAndAddSDS_Insert_SDS_Mutation$data;
  variables: VerifyAndAddSDS_Insert_SDS_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "safety_data_sheet_mutation_response",
    "kind": "LinkedField",
    "name": "insert_safety_data_sheet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyAndAddSDS_Insert_SDS_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyAndAddSDS_Insert_SDS_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eb2a29748bf5b44dd387fc1e7e6ef982",
    "id": null,
    "metadata": {},
    "name": "VerifyAndAddSDS_Insert_SDS_Mutation",
    "operationKind": "mutation",
    "text": "mutation VerifyAndAddSDS_Insert_SDS_Mutation(\n  $objects: [safety_data_sheet_insert_input!]!\n) {\n  insert_safety_data_sheet(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fb1fba89cdd4414bd1e463517fdd1c3";

export default node;
