import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import getColorAndCompleted from "./getColorAndCompleted";
import { MaterialSymbol } from "react-material-symbols";

type IncidentByPk = NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;

interface MenuItem {
  label: React.ReactNode;
  icon: React.ReactNode;
}

export type IncidentType =
  | "environmental"
  | "equipment"
  | "near_miss"
  | "property_damage"
  | "vehicle"
  | "utility"
  | "theft"
  | "injury_illness";

type IncidentMenuItemType =
  | "project"
  | "root_cause"
  | "witness"
  | "notes"
  | "logs"
  | "incident_detail"
  | "table"
  | IncidentType;

const environmentalMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.environmental_incident?.cause?.en &&
    !!incident.environmental_incident?.type &&
    !!incident.environmental_incident?.nature?.en &&
    !!incident.environmental_incident.completed_at;

  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "environmental",
  );

  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Environmental{extraLabel}</div>;
};

const equipmentMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    (incident.equipment_incident?.property_damaged === false ||
      (incident.equipment_incident?.property_damaged === true &&
        !!incident.equipment_incident.property_damage.en)) &&
    (incident.equipment_incident.operator_violated_safety_rules === false ||
      (incident.equipment_incident.operator_violated_safety_rules === true &&
        !!incident.equipment_incident.rules_violated?.en)) &&
    !!incident.equipment_incident.completed_at;
  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "equipment",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Equipment{extraLabel}</div>;
};

const nearMissMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.near_miss_incident?.severity &&
    incident.near_miss_incident?.severity?.length > 0 &&
    !!incident.near_miss_incident?.type &&
    incident.near_miss_incident?.type?.en.length > 0 &&
    !!incident.near_miss_incident.completed_at;
  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "near_miss",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Near Miss{extraLabel}</div>;
};

const propertyDamageMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.property_damage_incident?.cause.en &&
    !!incident.property_damage_incident.completed_at;
  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "property_damage",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Property Damage{extraLabel}</div>;
};

const utilityMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.utility_incident?.service_hit &&
    !!incident.utility_incident.completed_at;
  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "utility",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Utility Strike{extraLabel}</div>;
};

const vehicleMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.vehicle_incident?.cause &&
    !!incident.vehicle_incident.completed_at;

  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "vehicle",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Vehicle{extraLabel}</div>;
};

const injuryMenuLabel = (incident: IncidentByPk) => {
  const injuredUsersLen = incident.injured_users.length;
  const isInjuryAdded = incident.incident_types.some(
    (type) => type.type_value === "injury_illness",
  );

  if (isInjuryAdded && injuredUsersLen === 0) {
    return (
      <div className="text-semantic-negative">Injured Persons (Incomplete)</div>
    );
  } else {
    return <div>Injured Persons ({injuredUsersLen})</div>;
  }
};

const theftMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.theft_incident?.stolen_items &&
    !!incident.theft_incident.completed_at;
  const isEnvAdded = incident.incident_types.some(
    (type) => type.type_value === "theft",
  );
  const { className, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={className}>Theft / Vandalism{extraLabel}</div>;
};

const incidentDetailMenuLabel = (incident: IncidentByPk) => {
  const reqFieldsComplete =
    !!incident.lighting_conditions &&
    !!incident.summary &&
    !!incident.description.en &&
    !!incident.incident_time &&
    !!incident.detail_completed_at &&
    ((incident.emergency_service_called && !!incident.emergency_service) ||
      !incident.emergency_service_called);

  if (reqFieldsComplete) {
    return <div>Incident Details</div>;
  } else {
    return (
      <div className="text-semantic-negative">
        Incident Details (Incomplete)
      </div>
    );
  }
};

const getMenuItem = (
  incident: IncidentByPk,
  type: IncidentMenuItemType,
): MenuItem => {
  let label: React.ReactNode;
  let icon: React.ReactNode;
  let iconColor: "grey" | "black" | undefined;

  switch (type) {
    case "project":
      label = <div>Project Details</div>;
      icon = <MaterialSymbol icon="info" />;
      break;
    case "root_cause":
      label = <div>Root Cause Analysis</div>;
      icon = <MaterialSymbol icon="analytics" />;
      break;
    case "witness":
      label = `Witness ${
        incident.witnesses.length > 0 ? ` (${incident.witnesses.length})` : ""
      }`;
      icon = <MaterialSymbol icon="person" />;
      break;
    case "notes":
      label = <div>Notes</div>;
      icon = <MaterialSymbol icon="notes" />;
      break;
    case "logs":
      label = <div>History</div>;
      icon = <MaterialSymbol icon="history" />;
      break;
    case "environmental":
      label = environmentalMenuLabel(incident);
      icon = <MaterialSymbol icon="nature" />;
      break;
    case "equipment":
      label = equipmentMenuLabel(incident);
      icon = <MaterialSymbol icon="forklift" />;
      break;
    case "injury_illness":
      label = injuryMenuLabel(incident);
      icon = <MaterialSymbol icon="wounds_injuries" />;
      break;
    case "near_miss":
      label = nearMissMenuLabel(incident);
      icon = <MaterialSymbol icon="mood_bad" />;
      break;
    case "property_damage":
      label = propertyDamageMenuLabel(incident);
      icon = <MaterialSymbol icon="build" />; // could not find a better icon for now
      break;
    case "theft":
      label = theftMenuLabel(incident);
      icon = <MaterialSymbol icon="mood_bad" />;
      break;
    case "utility":
      label = utilityMenuLabel(incident);
      icon = <MaterialSymbol icon="bolt" />;
      break;
    case "vehicle":
      label = vehicleMenuLabel(incident);
      icon = <MaterialSymbol icon="car_crash" />;
      break;
    case "incident_detail":
      label = incidentDetailMenuLabel(incident);
      icon = <MaterialSymbol icon="details" />;
      break;
    case "table":
      label = <div>Back</div>;
      icon = <MaterialSymbol icon="arrow_back" />;
      break;
    default:
      label = <></>;
      icon = <></>;
  }

  return { label, icon };
};

export default getMenuItem;
