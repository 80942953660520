import NestedRoute from "src/common/types/manual/NestedRoute";
import GCNavigation from "./GCNavigation";
import RequireRoleWrapper from "../../../../common/components/ReqiureRoleWrapper";
import { Navigate, Outlet } from "react-router-dom";
import siteOrientationRoutes from "../../../../domain-features/siteorientation/siteOrientationEntryRoutes";
import gcHierarchyRoutes from "./hierarchy/gcHierarchyRoutes";
import GCProfile from "./settings/GCProfile";
import GCEmployeeVerification from "./employeeVerification/GCEmployeeVerification";
import React from "react";
import GCProcoreProjectSetup from "./GCProcoreProjectSetup";
import getGcCorporateOfficePath from "./hierarchy/corporate-office/utils/getGcCorporateOfficePath";

const gcRoutes: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      // do not change this route (it is used in procore manifest)
      path: "procore-project-setup",
      element: <GCProcoreProjectSetup />,
    },
    {
      path: "orientation",
      ...siteOrientationRoutes.inPersonOrientation,
    },

    {
      path: "",
      element: <GCNavigation />,
      children: [
        // TODO Add Home page
        // {
        //   path: "home",
        //   element: null,
        // },
        {
          path: "hierarchy",
          ...gcHierarchyRoutes,
        },
        {
          path: "settings",
          element: <GCProfile />,
          handle: {
            breadcrumb: "Profile",
          },
        },
        {
          path: "empver",
          element: (
            <Navigate
              to={getGcCorporateOfficePath("workers/worker-verification")}
            />
          ),
        },
        {
          path: "",
          element: <Navigate to={"hierarchy"} replace />,
        },
      ],
    },
  ],
};

export default gcRoutes;
