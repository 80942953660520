import { Typography } from "antd";
import IncidentStatus from "./IncidentStatus";

interface IncidentSubViewLayoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const IncidentSubViewLayout: React.FC<IncidentSubViewLayoutProps> = (props) => {
  return (
    <div className={`flex flex-col gap-1 w-full`}>
      <div className="flex flex-row justify-between items-center mb-1">
        <Typography.Title className={`mb-0`} level={2}>
          {props.title}
        </Typography.Title>
        <IncidentStatus />
      </div>

      {props.children}
    </div>
  );
};

export default IncidentSubViewLayout;
