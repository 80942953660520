/**
 * @generated SignedSource<<1a0709cb7b21a73e594365abc640eeb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertSubcontractorsMutation$variables = {
  subcontractorObjects: ReadonlyArray<project_subcontractor_insert_input>;
};
export type InsertSubcontractorsMutation$data = {
  readonly insert_project_subcontractor: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
};
export type InsertSubcontractorsMutation = {
  response: InsertSubcontractorsMutation$data;
  variables: InsertSubcontractorsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorObjects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "subcontractorObjects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertSubcontractorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertSubcontractorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6594b581b49acec19b5ef68bee144570",
    "id": null,
    "metadata": {},
    "name": "InsertSubcontractorsMutation",
    "operationKind": "mutation",
    "text": "mutation InsertSubcontractorsMutation(\n  $subcontractorObjects: [project_subcontractor_insert_input!]!\n) {\n  insert_project_subcontractor(objects: $subcontractorObjects) {\n    affected_rows\n    returning {\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29603baecbf10076d8495e8613432f17";

export default node;
