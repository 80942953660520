/**
 * @generated SignedSource<<a0968b46f0d053b917a877d8d5d7b6c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_poc_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddProjectWorkerPocModal_InsertMutation$variables = {
  object: project_worker_poc_insert_input;
};
export type AddProjectWorkerPocModal_InsertMutation$data = {
  readonly insert_project_worker_poc_one: {
    readonly id: string;
    readonly name: string;
    readonly phone_number: string;
    readonly pk: string;
    readonly title: string;
  } | null | undefined;
};
export type AddProjectWorkerPocModal_InsertMutation = {
  response: AddProjectWorkerPocModal_InsertMutation$data;
  variables: AddProjectWorkerPocModal_InsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProjectWorkerPocModal_InsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_worker_poc",
        "kind": "LinkedField",
        "name": "insert_project_worker_poc_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProjectWorkerPocModal_InsertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_worker_poc",
        "kind": "LinkedField",
        "name": "insert_project_worker_poc_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "484c621b998deb5906c818208679a15d",
    "id": null,
    "metadata": {},
    "name": "AddProjectWorkerPocModal_InsertMutation",
    "operationKind": "mutation",
    "text": "mutation AddProjectWorkerPocModal_InsertMutation(\n  $object: project_worker_poc_insert_input!\n) {\n  insert_project_worker_poc_one(object: $object) {\n    id\n    pk: id\n    title\n    name\n    phone_number\n  }\n}\n"
  }
};
})();

(node as any).hash = "acda604b3270896a3484f780eae0314e";

export default node;
