/**
 * @generated SignedSource<<fc7bc48307ac47efbe347bf6df625d9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { add_subcontractor_request_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SFRequestedSubcontractorsTotalRefetchableQuery$variables = {
  where: add_subcontractor_request_bool_exp;
};
export type SFRequestedSubcontractorsTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SFRequestedSubcontractors_total">;
};
export type SFRequestedSubcontractorsTotalRefetchableQuery = {
  response: SFRequestedSubcontractorsTotalRefetchableQuery$data;
  variables: SFRequestedSubcontractorsTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SFRequestedSubcontractorsTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SFRequestedSubcontractors_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SFRequestedSubcontractorsTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allSubcontractorsToAddConnection",
        "args": (v1/*: any*/),
        "concreteType": "add_subcontractor_requestConnection",
        "kind": "LinkedField",
        "name": "add_subcontractor_request_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "add_subcontractor_requestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "add_subcontractor_request",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8523851bbc02874c8938fef4eb443a98",
    "id": null,
    "metadata": {},
    "name": "SFRequestedSubcontractorsTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query SFRequestedSubcontractorsTotalRefetchableQuery(\n  $where: add_subcontractor_request_bool_exp!\n) {\n  ...SFRequestedSubcontractors_total_3FC4Qo\n}\n\nfragment SFRequestedSubcontractors_total_3FC4Qo on query_root {\n  allSubcontractorsToAddConnection: add_subcontractor_request_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "555c087a6a8a791ffa3fca31332d41ef";

export default node;
