import React from "react";
import EnvironmentalIncident from "./incident-types/EnvironmentalIncident";
import EquipmentIncident from "./incident-types/EquipmentIncident";
import NearMissIncident from "./incident-types/NearMissIncident";
import IncidentDetail from "./IncidentDetail";
import PropertyDamageIncident from "./incident-types/PropertyDamageIncident";
import VehicleIncident from "./incident-types/VehicleIncident";
import UtilityIncident from "./incident-types/UtilityIncident";
import TheftIncident from "./incident-types/TheftIncident";
import IncidentManagementInjury from "./IncidentManagementInjury";
import IncidentNotesTable from "./table/IncidentNotesTable";
import IncidentProjectInfo from "./IncidentProjectInfo";
import RootCauseAnalysis from "./RootCauseAnalysis";
import WitnessDetail from "./incident-users/WitnessDetail";
import IncidentLogsTable from "src/domain-features/incident-management/components/table/IncidentLogsTable";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

interface IncidentComponentRendererProps {
  typeProps: IncidentTypeProps;
  subview: string | null;
  selectedIncidentUserKey: string | null;
}

const IncidentComponentRenderer: React.FC<IncidentComponentRendererProps> = ({
  typeProps,
  subview,
  selectedIncidentUserKey,
}) => {
  switch (subview) {
    case "project_info":
      return <IncidentProjectInfo {...typeProps} />;
    case "incident_detail":
      return <IncidentDetail {...typeProps} />;
    case "environmental":
      return <EnvironmentalIncident {...typeProps} />;
    case "equipment":
      return <EquipmentIncident {...typeProps} />;
    case "near_miss":
      return <NearMissIncident {...typeProps} />;
    case "property_damage":
      return <PropertyDamageIncident {...typeProps} />;
    case "vehicle":
      return <VehicleIncident {...typeProps} />;
    case "utility":
      return <UtilityIncident {...typeProps} />;
    case "theft":
      return <TheftIncident {...typeProps} />;
    case "injured_user":
      return (
        <IncidentManagementInjury
          key={selectedIncidentUserKey}
          {...typeProps}
        />
      );
    case "witness":
      return <WitnessDetail key={selectedIncidentUserKey} {...typeProps} />;
    case "root_cause":
      return <RootCauseAnalysis {...typeProps} />;
    case "notes":
      return (
        <IncidentNotesTable
          incidentId={typeProps.incident.id}
          projectId={typeProps.incident.project_id}
        />
      );
    case "logs":
      return <IncidentLogsTable incidentId={typeProps.incident.id} />;
    default:
      return <IncidentProjectInfo {...typeProps} />;
  }
};

export default withCustomSuspense(IncidentComponentRenderer);
